@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';

.external-accounts-list {
  overflow: auto;
  max-height: 700px;
  padding: 0 5px;
  margin-top: 15px;

  @media screen and (max-width: 769px) {
    max-height: none;
  }
  .external-accounts-section {
    margin: 35px 0;
    .external-accounts-header {
      font-size: 17px;
      text-align: center;
    }
  }

  .external-card {
    margin-bottom: 15px;
    cursor: pointer;
  }
}
