.mobile-app-phone-img {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.mobile-app-phone-img img {
  max-width: 96%;
  max-height: 450px;
}

.mobile-app-promo-text {
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.mobile-app-phone-img-multi {
  width: 50%;
  height: auto;
}
