@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';

.activity-card {
  @include flex(column, center, center);
  position: relative;
  cursor: pointer;
  height: 142.5px;
  .activity-header {
    font-family: Akkurat-Bold;
    font-size: 20px;
  }

  .big-price {
    font-family: Akkurat-Light;
    font-size: 30px;
    color: #3627e4;
  }

  .activity-row {
    display: flex;

    .activity-row-center {
      display: flex;

      .activity-card-label {
        font-size: 15px;
        margin: 0 0 0 15px;
      }
    }
  }
}
