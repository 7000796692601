@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.asa-login-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: $primary-color;
  border-radius: 5px;
  height: 64px;
  cursor: pointer;
  .asa-text {
    color: white;
    font-size: 15px;
    margin: 0;
    width: 60%;
    text-align: center;
  }

  .asa-logo {
    width: 18%;
  }
}
