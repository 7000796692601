@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';

.balance {
  @include flex(column, center, center);
  .big-price {
    font-family: Akkurat-Light;
    font-size: 43px;
    color: #3627e4;
  }

  .balace-section-small {
    width: 80%;

    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  .balance-section {
    width: 100%;
  }
}
