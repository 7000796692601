@import "../../../reuse/mixins.scss";
@import "../../../reuse/variables.scss";

.goal-investments-container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .header{
        font-weight: bold;
        font-size: 25px;
    }

    .initial-investment-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: rgba(128,128,128,.1);
        border-radius: 15px;
        margin-bottom: 15px;
        .header{
            font-weight: normal;
            font-size: 20px;
            margin-bottom: 0;
        }
    }

    .goal-amount-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: rgba(128,128,128,.1);
        border-radius: 15px;
        margin-bottom: 15px;
        .header{
            font-weight: normal;
            font-size: 20px;
            margin-bottom: 0;
        }
    }

    .years-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: rgba(128,128,128,.1);
        border-radius: 15px;
        .header{
            font-weight: normal;
            font-size: 20px;
            margin-bottom: 0;
        }
        .p-dropdown{
            width: 30%;
        }
    }

    .message-container{
        padding: 15px;
        background: rgba(240,230,140,.3);
        border: 2px solid rgb(240,230,140);
        border-radius: 15px;
        margin-bottom: 25px;
        .message{
            color: rgba(189,183,107,.8);
            text-align: center;
        }
    }

    .button-container{
        text-align: right;
        margin-top: 25px;
        .submit-button{
            border-radius: 15px !important;
            color: white !important;
            background: $goals-color !important; 

            .next-button-container{
                display: flex;
                justify-content: center;
                align-items: center;
                .next-text{
                    margin: 0;
                    margin-right: 5px;
                }
            }
        }
    }
}