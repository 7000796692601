@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.recurring-buy-dialog-container {
}

.top-row {
  display: flex;
  justify-content: space-evenly;
  .buy-amount-form-container {
    width: 30%;
    .p-inputnumber {
      width: 100%;
    }
  }
  .frequency-form-container {
    width: 30%;
    .p-dropdown {
      width: 100%;
    }
  }
}
.roundup-form-container {
  display: flex;
  align-items: center;
  margin: 15px 0;
  justify-content: center;
  .label {
    margin: 0;
    margin-left: 10px;
  }
}
.btn-container {
  width: 30%;
  margin: auto;
  .submit-btn {
    width: 100%;
    background-color: $primary-color;
    color: white;
  }
}
.error {
  text-align: right;
  color: red;
}
.success {
  text-align: right;
  color: $primary-color;
}

.select-account-form-container {
  width: 60%;
  margin: auto;
  .p-dropdown {
    width: 100%;
  }
}
