@import "../../../reuse/mixins.scss";
@import "../../../reuse/variables.scss";

.group-crypto-basic-info-container {
  text-align: center;
  padding: 50px 0;
  .group-crypto-name {
    font-family: Akkurat-Bold;
    font-size: 16px;
    margin: 0;
  }
  .group-crypto-value {
    font-size: 43px;
    font-family: Akkurat-Light;
    margin: 0;
    color: $group-color;
  }
  .details-container {
    border-bottom: 0.25px solid rgba(0, 0, 0, 0.1);
    border-top: 0.25px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    .pl-container {
      width: 60%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      .pl-label {
        margin: 0;
      }
      .pl-value {
        margin: 0;
      }
    }
  }
}
