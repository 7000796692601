.strategies-title {
  font-size: 2.4rem;
}

.strategies-subtitle {
  font-size: 1.6rem;
  color: $theme-green;
}

.strategies-title-container {
  padding: 3rem 2rem 2rem 2rem;
}

@media screen and (max-width: 600px) {
  .strategies-title {
    font-size: 1.6rem;
  }

  .strategies-subtitle {
    font-size: 1.2rem;
    color: $theme-green;
  }
}

.charts-fluid-container {
  background-color: $background-grey;
  text-align: center;
}

.charts-chart-row {
  text-align: center;
}

.charts-container {
  padding: 2rem 0rem 2rem 0rem;
}

.charts-title {
  font-size: 3rem;
  color: $black;
  padding: 0rem 2rem 0rem 2rem;
}

.charts-charts-carousel {
  padding: 0rem 0rem 0rem 0rem;
}

.carousel-control-next-icon {
  margin-left: 10rem;
}

.carousel-control-prev-icon {
  margin-right: 10rem;
}

.carousel {
  width: 100%;
  height: 100%;
}

.carousel-indicators li {
  visibility: hidden;
}

.chart-title {
  font-size: 1.6rem;
  color: $theme-grey;
}

.chart-subtitle {
  font-size: 1.2rem;
  color: $font-text-grey;
}

.chart-subtitle a {
  font-size: 1.2rem;
  color: $theme-green;
}

.linecharts-fluid-container {
  background-color: $black;
  padding: 4rem 0rem 4rem 0rem;
}

.linechart-title {
  color: $white;
  font-size: 1.4rem;
}

.linechart-img-row {
  padding: 0rem 0.3rem 0rem 0.3rem;
}

.linechart-title-col {
  text-align: center;
}

.linecharts-single-chart {
  padding: 0rem 2rem 0rem 2rem;
  margin-left: auto;
  margin-right: auto;
}

.linecharts-container {
  padding: 0rem 20rem 0rem 20rem;
}

.strategies-policy-container {
  background-color: $background-grey;
  padding: 4rem 2rem 4rem 2rem;
  text-align: justify;
}

.strategies-policy-text {
  color: $theme-grey;
}

.fund-dash-chart {
  height: 650px;
}

@media screen and (max-width: 600px) {
  .fund-dash-chart {
    height: 1400px;
  }

  .chart-title {
    font-size: 1.2rem;
    color: $theme-grey;
  }

  .chart-subtitle {
    font-size: 0.9rem;
    color: $font-text-grey;
  }

  .chart-subtitle a {
    font-size: 0.9rem;
    color: $theme-green;
  }

  .charts-title {
    font-size: 1.5rem;
  }

  .strategies-policy-text {
    font-size: 0.7rem;
  }
}
