.FreeTrial-left-container{
    padding: 0rem 2.3rem 0rem 1.4rem;
}

.FreeTrial-left-title{
    font-size: 2.3rem;
    padding: 0rem 0rem 1rem 0rem;
}

.FreeTrial-left-subtitle{
    font-size: 1.2rem;
    color: $theme-grey;
    text-align: justify;
    padding: 0rem 0rem 1rem 0rem;
}

.FreeTrial-left-text-row{
    border-bottom: 1px solid $font-text-grey;
}

.FreeTrial-left-text{
    color: $theme-green;
    font-size: 1.5rem;
    padding: 2rem 0rem 0rem 0rem;
}

.FreeTrial-left-contact-info{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding: 0.2rem 0rem 2rem 1.2rem;
    font-size: 1.3rem;
    color: $theme-grey;
}

.FreeTrial-left-email-row{
    padding: 0.6rem 0rem 0rem 0rem;
}

.FreeTrial-left-contact-info a{
    color: $theme-green;
}

.FreeTrial-right-container{
    background-color: $background-grey;
    border-radius: 2rem;
    padding: 2rem 2rem 2rem 2rem;
}

.FreeTrial-right-title{
    font-size: 2.3rem;
    color: $theme-green;
    padding: 0rem 0rem 0.4rem 0rem;
}

.FreeTrial-right-subtitle{
    font-size: 1rem;
    padding: 0rem 0rem 0rem 0rem;
    color: $theme-grey;
}

.FreeTrial-right-red-spark{
    color: $red;
}

.FreeTrial-right-field-tag{
    font-size: 1.3rem;
    padding: 0rem 0rem 0.5rem 0rem;
    color: $theme-grey;
}

.FreeTrial-right-field-input-row{
    padding: 0rem 0rem 1.3rem 0rem;
}

.FreeTrial-right-field-input{
    width: 30rem;
    height: 2.5rem;
}

.FreeTrial-right-field-input:focus{
    outline-color: $theme-green;
}

.FreeTrial-right-field-submit{
    border: none;
    background-color: $theme-green;
    border-radius: 0.6rem;
    color: $white;
    font-size: 1.3rem;
    padding: 0.1rem 0.8rem 0.1rem 0.8rem;
}

.FreeTrial-right-field-submit:focus{
    outline:0;
}

.FreeTrial-right-submit-container{
    background-color: $background-grey;
    padding: 11.7rem 0rem 11.7rem 0rem;
    border-radius: 2rem;
}

.FreeTrial-right-submit-text{
    font-size: 1.6rem;
    padding: 0rem 3rem 0rem 3rem;
    color: $theme-green;
}

.FreeTrial-right-getback{
    border: none;
    background-color: $theme-green;
    border-radius: 0.6rem;
    color: $white;
    font-size: 1.3rem;
    padding: 0.1rem 0.8rem 0.1rem 0.8rem;
}

.FreeTrial-right-getback-row{
    padding: 1rem 3rem 0rem 4.1rem;
}