@import "../reuse/mixins.scss";
@import "../reuse/variables.scss";

.custom-tooltip {
  background: #f6f6f6;
  border : 1px solid rgba(128,128,128,.5);
  padding: 1px 15px;
  border-radius: 5px;
  .label {
    margin: 0;
    font-size: 12px;
    text-align: center;
    color: grey;
  }
  .value {
    margin: 0;
    font-size: 12px;
    color: $primary-color;
    text-align: center;
  }
}
