@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';
.budgeting {
  padding: 15px;
  width: 100%;
  height: 100vh;
  @include flex(row, space-between, center);
  @media screen and (max-width: 769px) {
    flex-direction: column;
    height: auto;
  }
  .left {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
    .p-tabview-panels {
      max-height: calc(95vh - 50px);
      overflow: auto;

      @media screen and (max-width: 769px) {
        max-height: none;
      }
    }

    .plus-icon {
      width: 30px;
      height: 30px;
      margin-left: 15px;
    }
    .add-account-container {
      @include flex(row, center, center);
      width: 200px;
      margin: auto;
      margin-right: 0;
      cursor: pointer;
      padding: 5px !important;
      .add-account-title {
        margin: 0;
        font-size: 15px;
      }
    }

    .p-tabview-nav-container {
      height: 50px;
    }

    .p-tabview .p-tabview-nav {
      background-color: transparent;
      margin: 0 20px;
      .p-tabview .p-tabview-nav {
        background-color: transparent;
        border: solid rgba(0, 0, 0, 0.12);
        li .p-tabview-nav-link {
          background-color: transparent;
          background: transparent;
          border-bottom: 0;
        }
      }

      li .p-tabview-nav-link {
        background: transparent !important;
      }
    }

    .p-tabview-panels {
      background-color: transparent;
      background: transparent !important;
      height: 100%;
    }
  }
  .right {
    width: 28%;
    height: 100%;
    padding-right: 5px;
    overflow-y: auto;
    padding-top: calc(1rem + 50px);
    @media screen and (max-width: 769px) {
      width: 100%;
      padding-top: 0;
    }
  }
  .sub-header {
    font-size: 13px;
    color: #c4c4c4;
    font-family: Akkurat-Bold;
  }
  .sub-section {
    margin: 15px 0;
    width: 100%;
  }
  .title {
    font-size: 13px;
    font-family: Akkurat-Bold;
  }
  .underline {
    width: 100%;
    border-bottom: 1px rgba(128, 128, 128, 0.2) solid;
  }
  .no-margin {
    margin: 0;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .summary {
    height: 400px;
    p {
      margin-bottom: 0.5rem;
    }
  }
  .transactions {
    height: 46%;
    margin-bottom: 15px;
    @media screen and (max-width: 769px) {
      margin-top: 25px;
    }
  }
  .right-arrow {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 15px;
    width: 8px;
  }
  .budgeting-block {
    margin-bottom: 15px;
  }

  .input-label-fixed {
    width: 80%;
    margin: 0;
  }
}

.back-icon {
  width: 25;
  height: 25;
  cursor: pointer;
  margin-bottom: 25px;
}

.shadow-card {
  background-color: white;
  border-radius: 19px;
  padding: 25px;
  box-shadow: rgb(255 255 255 / 92%) -3px -2px 4px,
    rgb(86 86 86 / 20%) 3px 1px 5px, rgb(255 255 255 / 21%) 0px 0px 2px inset;
  width: 100%;
  position: relative;

  @media screen and (max-width: 769px) {
    padding: 25px 15px;
  }
}

.budgeting-row {
  @include flex(row, space-between, center);
  width: 100%;
}
.budgeting-row-small {
  @include flex(row, space-between, center);
  width: 50%;
  margin: auto;
}

.budgeting-label {
  color: black;
  font-size: 15px;
  text-align: left;
}

.budgeting-value {
  color: black;
  font-size: 15px;
}

.budgeting-value-sub-text {
  color: rgba(128, 128, 128, 0.5);
  font-size: 12px;
}

.no-margin {
  margin: 0;
}

.big-text {
  font-size: 20px;
  @media screen and (max-width: 769px) {
    font-size: 12px;
  }
}

.blur {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 100000;
  position: absolute;
  top: 0;
  left: 0;
  @include flex(row, center, center);
}

.subs-msg {
  font-size: 50px;
  color: white;
}

.balance-scrollable {
  //min-height: 400px;
  overflow: auto;
}

.grey-dialog {
  .p-dialog-content {
    background: rgb(246, 246, 246) !important;
  }
  .p-dialog-header {
    background: rgb(246, 246, 246) !important;
  }
}
