/* color */
$white-hover: #d0d4d4;
$theme-green: #00A99D;
$theme-grey: #606874;
$red: #f16268;
$font-title-grey: #444;
$font-text-grey: #787878;
$white: #FFFFFF;
$black: #000000;
$background-grey: #f8f9fa;
