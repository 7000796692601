@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';
.transactions-history {
  padding: 0 0 15px 0;
  height: 100%;
  .transactions-title {
    font-size: 16px;
    color: #272748;
    font-weight: bold;
    text-transform: uppercase;
  }
  .tab-container {
    @include flex(row, flex-start, center);
  }
  .transactions-history-tabs {
    width: 100%;
    @include flex(row, flex-start, center);
    cursor: pointer;
    padding-bottom: 10px;
    overflow-x: auto;
    .transactions-history-tab {
      margin: 0 10px;
      .transactions-history-tab-label {
        margin: 0;
        font-size: 15px;
        display: inline;
        white-space: nowrap;
      }
      .selected {
        color: $primary-color;
      }
    }
  }
  .transactions-table-values {
    overflow-y: auto;
    margin-top: 25px;
    max-height: 600px;
    .transactions-table-value {
      border-top: 1px solid rgba(128, 128, 128, 0.3);
      border-bottom: 1px solid rgba(128, 128, 128, 0.3);
      padding: 15px;
      .transactions-row-value {
        font-size: 15px;
      }
      .blue-text {
        color: $primary-color;
      }
      .light-text {
        font-family: Akkurat-Bold;
        color: rgba(128, 128, 128, 1);
      }
      .no-margin {
        margin: 0;
      }
    }
    .no-msg-container {
      @include flex(row, center, center);
      height: 100%;
      .no-msg {
        font-size: 20px;
        font-family: 'Akkurat-Bold';
        text-align: center;
      }
    }
  }
}
