@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.link-bank-btn {
  background-color: $primary-color;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07),
  //   -3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2),
  //   inset 0px 0px 2px rgba(255, 255, 255, 0.21);
  border-radius: 19px;
  width: 125px;
  height: 40px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .link-bank-label {
    font-size: 12px !important;
    color: white;
    margin: 0;
    text-align: center;
  }
}

.link-bank-icon {
  cursor: pointer;
}
