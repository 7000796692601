@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';

.piecharts-card {
  padding: 25px 0;
  width: 80%;
  margin: auto;
  @media screen and (max-width: 769px) {
    padding: 0;
    width: 100%;
  }
  .piecharts-tabs {
    @include flex(row, center, center);
    margin-bottom: 25px;
  }

  .piecharts-sub-tabs {
    @include flex(row, space-between, center);
    border-top: 1px solid rgba(128, 128, 128, 0.2);
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    overflow-x: auto;

    .piecharts-sub-tab {
      padding: 15px 10px;
      text-transform: capitalize;
      cursor: pointer;
      margin-bottom: 0;
    }

    .piecharts-sub-tab-selected {
      padding: 15px 10px;
      margin-bottom: 0;
      text-transform: capitalize;
      cursor: pointer;
      color: $primary-color;
      border-bottom: 1px solid $primary-color;
    }
  }
  .piecharts-container {
    width: 100%;
    height: 300px;
  }

  .chart-legend {
    .chart-legend-row {
      @include flex(row, space-between, center);
      width: 100%;
      margin: auto;
      .chart-legend-label {
        font-size: 15px;
      }
      .chart-legend-value {
        font-size: 15px;
      }
    }
  }
}
