@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.verification-options-container {
  .options-row {
    display: flex;
    margin: 25px 0;
    justify-content: space-evenly;
    .options-container {
      display: flex;
      justify-content: center;
      .option-label {
        margin: 0;
      }
      .p-radiobutton .p-radiobutton-box.p-highlight {
        border-color: $primary-color;
      }
      .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background: $primary-color;
      }
    }
  }
  .options-submit-btn-container {
    display: flex;
    justify-content: center;
    .options-submit-btn {
      border-radius: 25px;
      background: $primary-color;
      color: white;
    }
  }
  .have-code-container {
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
    font-size: 13px;
  }
}

.verification-options-container-small {
  width: 100%;
  .options-row {
    display: flex;
    margin: 25px 0;
    justify-content: space-evenly;
    .options-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .option-label {
        margin: 0;
        margin-left: 5px;
      }
      .p-radiobutton .p-radiobutton-box.p-highlight {
        border-color: $primary-color;
      }
      .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background: $primary-color;
      }
    }
  }
  .options-submit-btn-container {
    display: flex;
    justify-content: center;
    .options-submit-btn {
      border-radius: 25px;
      background: $primary-color;
      color: white;
    }
  }
  .have-code-container {
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
    font-size: 13px;
  }
}

.top-container-header {
  font-family: Akkurat-Bold;
  font-size: 20px;
}

.forgot-pw-btn {
  margin-top: 1.5rem;
  cursor: pointer;
}

.verification-error-msg {
  text-align: center;
  align-self: center !important;
  color: red;
}

.verification-success-msg {
  text-align: center;
  align-self: center !important;
  color: $primary-color;
}

.error-msg {
  text-align: center;
  width: 100%;
  color: red;
  margin-top: 15px;
}

.resend__button {
  text-align: center;
  align-self: center !important;
  margin-left: 5px;
}

.forgot-pw-close-icon {
  position: absolute;
  right: 0;
  top: 0;
  margin: 15px;
  cursor: pointer;

  .icon {
    height: 30px;
    width: 30px;
  }
}

.forgot-pw-input {
  font-size: 1rem;
  width: 100%;
  border: solid 1px lightgrey;
  border-radius: 5px;
  padding: 15px;
}

.main-navbar-submt-btn-container {
  .main-navbar-submit-btn {
    background: $primary-color;
    color: white;
    border-radius: 25px;
    margin-top: 15px;
  }
}

// .password-container {
//   width: 100%;
//   position: relative;
//   .password-toggle-icon {
//     width: 20px;
//     height: 20px;
//     position: absolute;
//     top: 0;
//     right: 0;
//     margin: 18px;
//     cursor: pointer;
//   }
// }

// .navbar__container__background__white {
//   .language-toggle-container {
//     margin-bottom: 0;
//     padding-left: 25px;
//     @include flex(row, flex-end, center);
//     position: relative;
//     right: 0;
//     padding: 5px;
//     .language-header {
//       margin: 0;
//       text-align: left;
//       font-size: 15px;
//       margin-right: 15px;
//       color: rgba(128, 128, 128, 0.5);
//       width: 100%;
//       height: 100%;
//     }
//     .p-dropdown {
//       border-color: #cccccc;
//       // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07),
//       //   -3px -2px 4px rgba(255, 255, 255, 0.92),
//       //   3px 1px 5px rgba(86, 86, 86, 0.2),
//       //   inset 0px 0px 2px rgba(255, 255, 255, 0.21);
//       box-shadow: none;
//       border-radius: 25px;
//       padding: 5px;
//       width: 90px;
//       height: 30px;
//       // background: #f6f6f6;
//       background: white;
//     }
//     .p-dropdown:hover {
//       border-color: $primary-color !important;
//     }
//     .p-dropdown .p-inputtext {
//       font-size: 12px;
//       padding-left: 15px;
//       text-align: center;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       color: rgba(128, 128, 128, 1);
//     }
//     .pi {
//       font-size: 12px !important;
//     }
//     .p-dropdown-trigger {
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       flex-shrink: 1;
//     }
//   }
// }

.language-toggle-container-navbar {
  .changed-color-language-toggle {
    .p-dropdown-trigger {
      display: flex;
      flex-direction: column;
      color: black !important;
      justify-content: center;
      align-items: center;
      flex-shrink: 1;
    }
  }
  margin-bottom: 0;
  padding-left: 25px;
  @include flex(row, center, center);
  right: 0;
  padding: 5px;
  margin-right: 5px;
  .language-header {
    margin: 0;
    text-align: left;
    font-size: 15px;
    margin-right: 15px;
    color: rgba(128, 128, 128, 0.5);
    width: 100%;
    height: 100%;
  }
  .p-dropdown {
    border-color: transparent;
    box-shadow: none;
    border-radius: 25px;
    padding: 5px;
    width: 70px;
    height: 30px;
    // background: #f6f6f6;
    background: transparent;
    @include flex(row, center, center);
  }
  .p-dropdown:hover {
    border-color: $primary-color !important;
  }
  .p-dropdown .p-inputtext {
    font-size: 12px;
    padding-left: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(128, 128, 128, 1);
  }
  .pi {
    font-size: 12px !important;
  }
  .p-dropdown-trigger {
    display: flex;
    flex-direction: column;
    color: white !important;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 5px !important;
  @include flex(row, center, center);
}

.top-header-right-container {
  @include flex(row, center, center);

  .signup-btn-container {
    margin-right: 15px;
    .navbar-signup-btn {
      border: none;
      border-radius: 25px;
      width: 100px;
      cursor: pointer;
      height: 30px;
      color: white;
      background-color: $primary-color;
      .signup-label {
        font-size: 12px;
        margin: 0;
        text-align: center;
      }
    }
  }
}

.scrolled {
  // background-color: #36454f !important;
  transition: background-color 200ms linear;
  z-index: 999;
  background-color: #f6f6f6 !important;
  color: black !important;
}

.blue {
  background-color: #5255f6 !important;
}

// .flag-container {
//   width: 35px;
//   height: 35px;

//   .flag {
//     width: 100%;
//     height: 100%;
//     object-fit: scale-down;
//     border-radius: 25px;
//   }
// }

.flag {
  width: 25px;
  height: 25px;
}

.session-timeout-message-container {
  border: 1px solid red;
  border-radius: 5px;
  background-color: rgba(255, 0, 0, 0.2);
  .session-timeout-message {
    color: red;
    padding: 0 5px;
  }
}
