@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.apex-kyc-container {
  text-align: center;
  .kyc-status-message {
    font-size: 15px;
    font-family: Akkurat-Bold;
    color: white;
    background-color: #3627e4;
    width: auto;
    margin: 15px auto;
    border-radius: 15px;
    padding: 5px;
    width: 50%;
  }
  .apex-kyc-header {
    font-family: Akkurat-Bold;
    font-size: 20px;
  }
  .file-options-container {
    font-size: 15px;
    .file-options-header {
    }
    .p-dropdown {
      width: 80%;
    }
  }

  .file-upload-container {
    width: 100%;
    margin: auto;
    margin-top: 25px;
    .p-button {
      background-color: $primary-color;
    }
  }

  .apex-kyc-submit-container {
    margin: 50px 0 0 0;
    .apex-kyc-submit-btn {
      border-radius: 25px;
      color: white;
      background: $primary-color;
      width: 50%;
    }
  }

  .error-msg {
    color: red;
    margin-top: 15px;
  }
  .success-msg {
    color: $primary-color;
    margin-top: 15px;
  }
}
