@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';
.credit-details {
    padding: 25px;
    width: 100%;
    position: relative;
    @media screen and (max-width: 769px) {
        width: 100%;
    }
    .blue-dot {
        height: 25px;
        width: 25px;
        top: 0;
        right: 0;
        cursor: pointer;
        background-color: $primary-color;
        border-radius: 50%;
        margin-left: 15px;
        @include flex(row, center, center);
        .blue-dot-i {
            font-size: 10px;
            margin: 0;
            color: white;
        }
    }
    .credit-message {
        margin: 0;
        color: rgba(128, 128, 128, 0.8);
        font-size: 12px;
    }
    .credit-text {
        font-size: 15px;
        font-family: 'Akkurat-Bold';
        margin: 0;
    }
    .credit-details-content {
        @include flex(column, center, center);
        .credit-details-header {
            font-size: 25px;
            font-family: 'Akkurat-Bold';
        }
        .credit-details-row {
            width: 100%;
            @include flex(row, center, center);
        }
        .credit-score {
            font-family: 'Akkurat-Bold';
            font-size: 25px;
            text-align: center;
            margin-left: 25px;
        }
        .credit-details-msg {
            font-size: 15px;
        }
        .underline {
            border-bottom: 1px solid rgba(128, 128, 128, 0.3);
            padding: 15px 0;
        }
        .no-margin {
            margin: 0;
        }
        .shift {
            margin-right: 25px;
        }
        .row-value-and-trend {
            display: flex;
        }
        .trend-icon {
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }
}