@import "../reuse/mixins.scss";
@import "../reuse/variables.scss";

.disclaimer-container{
    padding: 50px 50px;
    .disclaimer-heading{

    }
    .disclaimer-text{

    }
}