@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';

.account-status-msg {
  margin: 25px 0;
  background-color: rgba(255, 0, 0, 0.2);
  border-radius: 25px;
  padding: 10px;
  border: 1px solid red;
  .message {
    color: rgba(255, 0, 0, 1);
    margin: 0;
  }
}
