@import "../../../reuse/mixins.scss";
@import "../../../reuse/variables.scss";

.robo-specific-info-container{
    padding: 50px 0;
    height: 100%;
}

.allocation-dialog-header{
    text-align: center;
    margin: 0;
    padding-left: 10%;
}
