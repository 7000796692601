@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.customized-legend {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media screen and (max-width: 769px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .value-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .custom-legend-label {
      text-align: right;
    }
  }
}
