@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

//DIALOG CLASS NAMES
.deposit-container {
  padding: 0px 15px;
  .link-btn-container {
    @include flex(row, flex-end, center);
    margin-bottom: 25px;
  }
  .input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .label {
      margin-top: 0;
      margin-right: 15px;
      flex: 1;
    }
    .amount-input {
      flex: 2;
    }
    .account-dropdown {
      flex: 2;
    }
  }
  .deposit-error-message {
    text-align: center;
    color: red;
  }
  .deposit-success-message {
    text-align: center;
    color: $primary-color;
  }
  .deposit-button-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    .deposit-submit-button {
      border-radius: 15px;
      background: $primary-color;
      color: white;
    }
  }
  .p-dropdown {
    width: 100%;
  }
}
