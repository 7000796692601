@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';
.external-account-details {
  padding: 25px;
  width: 65%;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
  .actions-buttons-container {
    @include flex(row, flex-end, center);
    position: absolute;
    right: 0;
  }
  .edit-icon {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    cursor: pointer;
  }
  .header-row {
    @include flex(row, center, center);
    margin-bottom: 35px;
    position: relative;
    .edit-icon {
      width: 25px;
      height: 25px;
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .account-name {
    text-align: center;
    font-family: 'Akkurat-Bold';
    font-size: 25px;
    margin: 0;
    margin-right: 15px;
  }
  .account-summary-content {
    width: 80%;
    margin: auto;
  }
  .account-summary {
    border-top: 1px solid rgba(128, 128, 128, 0.3);
    padding: 25px 0;

    .honely-container {
      @include flex(column, flex-end, flex-end);
    }
  }
  .transactions-table-values {
    overflow-y: auto;
    height: 70vh;
    .transactions-table-value {
      border-top: 1px solid rgba(128, 128, 128, 0.3);
      padding: 15px;
      .transactions-row-value {
        font-size: 15px;
      }
      .blue-text {
        color: $primary-color;
      }
      .light-text {
        font-family: Akkurat-Bold;
        color: rgba(128, 128, 128, 1);
      }
      .no-margin {
        margin: 0;
      }
    }
  }
  .wallet-summary {
    .p-accordion-header-link {
      text-decoration: none;
    }
    .no-nft-msg {
      font-size: 25px;
      text-align: center;
    }
    .nft-header {
      display: flex;
      align-items: center;
      width: 100%;
      .collection-image-container {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }
      .header-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .nft-label {
        margin: 0;
        font-size: 20px;
        font-family: 'Akkurat-Bold';
      }
      .nft-label-small {
        margin: 0;
        font-size: 13px;
        color: rgba(128, 128, 128, 0.5);
      }
    }
    .nfts {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      .nft-card-container {
        height: 195px;
        width: 150px;
        margin: 0 5px 25px 5px;
        text-decoration: none;
        cursor: pointer;
        text-overflow: ellipsis;
      }
    }
  }
}

.space-top {
  margin-top: 15px;
}
