@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';
.activity-details {
  padding: 25px;
  width: 100%;

  @media screen and (max-width: 769px) {
    width: 100%;
    padding: 15px 0;
  }

  .big-price {
    font-family: Akkurat-Light;
    font-size: 30px;
    color: #3627e4;
  }
  .activity-details-content {
    @include flex(column, center, center);
    .activity-details-header {
      font-size: 25px;
      font-family: 'Akkurat-Bold';
      margin: 0;
    }
    .activity-details-section {
      margin: 15px 0;
      width: 100%;
    }
    .activity-details-section-header {
      font-size: 20px;
      font-family: 'Akkurat-Bold';
      text-align: center;
      text-transform: capitalize;
    }
    .activity-row {
      @include flex(row, space-between, center);
    }
    .activity-label {
      color: black;
      font-size: 15px;
    }
    .activity-value {
      color: black;
      font-size: 15px;
    }
    .activity-details-dropdown-container {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: center;
    }
    .graph-tabs {
      display: flex;
      justify-content: space-evenly;
      height: 20%;
      max-height: 20%;
      align-items: center;
      margin-top: 25px;
      .graph-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 15px;
        width: 100px;
        height: 40px;
        color: #a7a7a7;
        margin: 0;
        text-align: center;
      }
      .graph-tab-big {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 15px;
        width: 120px;
        height: 30px;
        color: #a7a7a7;
        text-align: center;
      }
      .selected {
        color: $primary-color;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07),
          -3px -2px 4px rgba(255, 255, 255, 0.92),
          3px 1px 5px rgba(86, 86, 86, 0.2),
          inset 0px 0px 2px rgba(255, 255, 255, 0.21);
        border-radius: 19px;
        text-align: center;
      }
    }
    .insights {
      width: 70%;
      margin: auto;
      .insights-desc {
        font-size: 15px;
      }
      .bar-content {
        margin: 25px 0;
        .bar-label {
          margin: 0;
          font-size: 15px;
        }
      }
    }
    .graph-legend {
      margin: 15px 0;
      @include flex(row, space-evenly, center);
      @media screen and (max-width: 769px) {
        flex-direction: column;
      }
      .legend-container {
        @include flex(row, center, center);
        .purple-dot {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $primary-color;
        }
        .black-dot {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: black;
        }
        .legend-label {
          font-size: 15px;
          margin: 0;
          margin-left: 5px;
        }
      }
      .legend-price {
        font-size: 15px;
        margin-top: 5px;
      }
      .purple {
        color: $primary-color;
      }
      .black {
        color: black;
      }
    }
    .activity-expenses-graph-container {
      margin: 25px 0;
    }
    .activity-expenses-row {
      @include flex(row, center, center);
    }
    .activity-expenses-label {
      font-size: 15px;
      margin-right: 20px;
    }
    .activity-expenses-value {
      font-size: 15px;
    }
    .activity-line-message {
      font-size: 20px;
      margin: 10px 0 20px 0;
    }
    .activity-emergency-funds {
      width: 70%;
      margin: auto;
      .bar-label {
        color: white;
        font-size: 15px;
        text-align: center;
        margin: 0;
      }
      .emergency-funds-amount {
        text-align: center;
        font-size: 17px;
      }
      .emergency-fund-message {
        font-size: 15px;
        margin: 30px 0 0 0;
      }
    }
    .bar-full-container {
      width: 100%;
      border: 1px solid $primary-color;
      border-radius: 35px;
    }
    .bar-container {
      height: 40px;
      border-radius: 25px;
      @include flex(row, center, center);
    }
  }
}
