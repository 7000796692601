.team-member-form{
    padding: 1rem 0rem 1rem 0rem;
}

.member-rectangle{
    padding: 1.3rem 1.6em 1.3rem 1.6rem;
    max-width: 540px;
    display: inline-block;
    border: 1px solid #CCC;
    border-radius: 30px;
    margin-right: 1.3rem;
    margin-bottom: 2.6rem;
    margin-left: 1.3rem;
}

.member-name{
    font-size: 28px;
    color: $theme-green;
}

.member-position{
    font-size: 14px;
    color: #000;
}

.member-introduction{
    font-size: 12px;
    color: #767676;
    text-align:justify
}

.team-member-row{
    padding: 0rem 0rem 1rem 0rem;
}