@import '../../../reuse/mixins.scss';
@import '../../../reuse/variables.scss';
.suggested-container {
    @include flex(column, space-between, center);
    width: 100%;
    height: 100%;
    padding: 0 25px;
    .suggested-title {
        font-family: 'Akkurat LL';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
    }
    .suggested-header-row {
        @include flex(row, space-between, center);
        margin-left: 25px;
        .suggested-header {
            font-family: 'Akkurat LL';
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 24px;
            flex: 1;
            margin: 0;
            text-align: center;
        }
        .right {
            text-align: right;
        }
        .suggested-header-small {
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 24px;
            flex: 1;
            margin: 0;
        }
    }
    .suggested-row {
        @include flex(row, space-between, center);
        height: 50px;
        padding: 10px 0;
        cursor: pointer;
        .suggested-value {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 40px;
            color: $primary-color;
            flex: 1;
            margin: 0;
            text-align: center;
        }
        .suggested-arrow {
            flex: 1;
            height: 100%;
            object-fit: contain;
            object-position: right;
        }
        .suggested-radio {
            width: 25px;
        }
        .bold {
            font-weight: 700;
        }
    }
    .suggested-btn-container {
        @include flex(row, center, center);
        margin: 15px 0;
        .suggested-btn {
            border: none;
            background-color: $primary-color;
            color: white;
            border-radius: 25px;
            height: 50px;
            width: 150px;
        }
    }
    .succes {
        color: $primary-color;
        font-size: 25px;
        text-align: center;
    }
    .error {
        color: red;
        font-size: 20px;
        text-align: center;
    }
}