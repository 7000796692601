@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';
.subscription-card {
    box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92), 5px 5px 5px rgba(86, 86, 86, 0.2), inset 0px 0px 2px rgba(255, 255, 255, 0.21);
    border-radius: 25px;
    width: 70%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    margin: auto;
    @media only screen and (max-width: 769px) {
        width: 95%;
    }
    .subscription-row {
        display: flex;
        align-items: center;
        .subscription-label {
            font-family: Akkurat-Bold;
            font-size: 15px;
            margin-right: 15px;
        }
        .highlight {
            background-color: $primary-color;
            color: white;
            border-radius: 25px;
            padding: 2px 25px;
        }
        .subscription-value {}
    }
    .downgrade-btn-container {
        width: 60%;
        margin: 15px auto 0;
        .downgrade-btn {
            width: 100%;
            color: white;
            background-color: $primary-color;
            border-radius: 25px;
        }
    }
}

.subscription-row {
    display: flex;
    justify-content: center;
    align-items: center;
    .subscription-label {
        font-family: Akkurat-Bold;
        font-size: 15px;
        margin-right: 15px;
    }
    .highlight {
        background-color: $primary-color;
        color: white;
        border-radius: 25px;
        padding: 2px 25px;
    }
    .subscription-value {}
}

.downgrade-btn-container {
    width: 60%;
    margin: 15px auto 0;
    .downgrade-btn {
        width: 100%;
        color: white;
        background-color: $primary-color;
        border-radius: 25px;
    }
}