@import '../../../reuse/mixins.scss';
@import '../../../reuse/variables.scss';

.ai-crypto-activation-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  height: 80vh;
  justify-content: space-between;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
  .ai-crypto-header {
    text-align: center;
    font-family: Akkurat-Bold;
    font-size: 25px;
    margin: 0;
  }
  .header-container {
    display: flex;
    justify-content: space-evenly;
    margin: 15px 0;
    .left-col {
      text-align: center;
      .label {
        font-size: 17px;
      }
      .value {
        font-size: 17px;
        font-weight: bold;
      }
    }
    .right-col {
      text-align: center;
      .label {
        font-size: 17px;
      }
      .value {
        font-weight: bold;
        font-size: 17px;
      }
    }
  }

  .initial-investment-form-container {
    padding: 30px 15px;
    background: rgba(128, 128, 128, 0.1);
    border-radius: 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 15px 0;
    @media screen and (max-width: 769px) {
      flex-direction: column;
    }
    .label {
      margin: 0;
      font-weight: bold;
      font-size: 17px;
    }
  }

  .requirement-message-container {
    border-radius: 15px;
    background: rgba(0, 255, 0, 0.2);
    border: 1px solid rgba(0, 255, 0, 1);
    padding: 10px 10px 10px 0;
    margin: 15px 0;
    .message {
      font-size: 15px;
      margin-bottom: 0;
      text-align: center;
    }
  }

  .cryptos-list-container {
    padding: 30px 0;
    box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
      3px 1px 5px rgba(86, 86, 86, 0.2),
      inset 0px 0px 2px rgba(255, 255, 255, 0.21);
    border-radius: 19px;
    margin: 15px 0;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
    .header {
      font-size: 17px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 0;
    }

    .crypto-row {
      display: flex;
      align-items: center;
      padding: 5px 15px;
      justify-content: space-between;
      cursor: default;
      .image-and-info {
        display: flex;
        .img-container {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          border: 1px solid grey;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          .not-found {
          }
          .image {
            border-radius: 50%;
            max-width: 100%;
            max-height: 100%;
          }
        }
        .info {
          .symbol {
            margin: 0;
            font-size: 15px;
          }
          .company {
            margin: 0;
            font-size: 12px;
            color: rgba(128, 128, 128, 0.9);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
      .delete-btn {
        color: black;
        background: transparent;
      }
    }
    .btn-container {
      width: 20%;
      margin: 10px auto;
      .add-cryptos-btn {
        border-radius: 25px;
        background: $group-color;
        color: white;
        width: 100%;
        padding: 5px;
      }
    }
  }
  .error {
    text-align: center;
    color: red;
  }
  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .submit-button {
      width: 50%;
      border-radius: 15px;
      color: white;
      background: $group-color;
    }
  }
}
