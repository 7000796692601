@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.promo-page {
  background: linear-gradient(180deg, #4346f8 0%, #9d8aff 100%);
  color: white;
  min-width: 100vw;
  min-height: 100vh;
  padding: 45px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include flex(column, center, center);

  .background {
    position: absolute;
    background-image: url('../../assets/promo-bubbles-big.png');
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    // background-position: left bottom;
    bottom: -100px;
    left: -100px;
    background-position: center bottom;
    @media only screen and (max-width: 600px) {
      background-image: url('../../assets/promo-bubbles.png');
      bottom: -50px;
      background-position: left bottom;
      left: 0;
    }
  }

  .backgroundimg {
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: -1;
    left: 0;
  }

  .shell-logo {
    width: 60px;
  }

  .space-bottom-big {
    margin-bottom: 50px;
  }

  .investall-header {
    font-family: 'Akkurat-Light';
    font-size: 52px;
    line-height: 50px;
    @media only screen and (max-width: 600px) {
      text-align: center;
    }
  }

  .investall-bold {
    font-size: 19px;
    font-family: 'Akkurat-Bold';
    line-height: 23px;
    @media only screen and (max-width: 600px) {
      text-align: center;
    }
  }

  .investall-small {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.66);
  }

  .download-app-btn {
    width: 280px;
    height: 55px;
    border-radius: 35px;
    background-color: white;
    color: $primary-color;
    font-size: 23px;
    font-weight: bold;
    border: none;
  }

  .list-elements {
    // list-style-position: inside;
    margin-left: 35px;
    li {
      font-size: 19px;
      font-family: 'Akkurat-Light';
      line-height: 23px;
    }
  }
}
