@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.notice {
  .notice-info {
    font-size: 15px;
  }

  .important {
    font-weight: 'bold';
    color: $primary-color;
  }
}

.remittance-info {
  .remit-header {
    font-weight: bold;
    font-size: 18px;
    background: rgba(128, 128, 128, 0.2);
    padding: 10px;
  }
  .remit-row {
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      margin: 0;
      width: 45%;
    }
    .value {
      width: 45%;
      margin: 0;
      .p-dropdown {
        width: 100%;
      }
    }
    .highlight {
      color: $primary-color;
      font-family: Akkurat-Bold;
    }
  }
  .notice-info-small {
    font-size: 15px !important;
  }
  .colored {
    background: rgba(128, 128, 128, 0.1);
  }
}

.download-btn-container {
  width: 50%;
  margin: auto;
  @include flex(row, center, center);
  .download-btn {
    color: white;
    background-color: $primary-color;
    border-radius: 25px;
    margin-top: 25px;
    text-align: center;
  }
}

.padding {
  // @include flex(column, center, center);
  padding: 15%;
}

.wire-deposit-header {
  text-align: center;
  font-size: 30px;
  font-family: Akkurat-Bold;
}
