@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';

// .events-container {
//   margin-top: 25px;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-evenly;
//   .news-link-container {
//     cursor: pointer;
//     width: 300px;
//     height: 100px;
//     margin-left: 25px;
//     margin-right: 25px;
//     margin-bottom: 25px;
//     border-radius: 25px;
//     border: 1px solid grey;
//     padding: 10px;
//     text-align: center;
//     text-overflow: ellipsis;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     .news-link {
//       font-size: 15px;
//       line-height: 20px;
//       display: -webkit-box;
//       -webkit-line-clamp: 3;
//       -webkit-box-orient: vertical;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       height: 75%;
//       margin: 0;
//       color: black;
//     }
//     .news-date {
//       height: 20%;
//       font-size: 12px;
//       color: rgba(128, 128, 128, 0.5);
//       margin: 0;
//     }
//   }
// }

.events-container {
  margin-top: 25px;
  margin-bottom: 25px;
  .news-link-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    .news-title {
      width: 65%;
      text-overflow: ellipsis;
      font-size: 16px;
      color: black;
      margin: 10px;
      overflow: hidden;
      white-space: nowrap;
    }

    .news-date {
      width: 30%;
      font-size: 16px;
      color: black;
      margin: 10px;
      text-align: right;
    }
  }
}
