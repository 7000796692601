@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.signin-container {
  min-height: 100vh;
  min-width: 100vw;
  background-color: $primary-color;
  @include flex(column, center, center);
  .signin-form-container {
    @media only screen and (max-width: 769px) {
      width: 100%;
    }
    .signin-header {
      line-height: 43px;
      font-size: 53px;
      color: white;
      text-align: center;
      margin-bottom: 35px;

      @media only screen and (max-width: 769px) {
        line-height: 23px;
        font-size: 30px;
        margin-bottom: 23px;
        margin-top: 18%;
      }
    }
    .signin-form {
      padding: 35px;
      background-color: white;
      border-radius: 5px;
      width: 500px;
      @media only screen and (max-width: 769px) {
        min-width: 90%;
        width: auto;
        margin: 15px;
        padding: 20px;
      }
      .or-text {
        font-size: 17px;
        color: black;
        text-align: center;
      }
      .signin-input {
        font-size: 17px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(128, 128, 128, 0.5);
        width: 100%;
        height: 64px;
      }
      .signin-btn-container {
        @include flex(column, flex-end, flex-end);
        @media only screen and (max-width: 769px) {
          justify-content: center;
        }
        .signin-button {
          border: none;
          width: 163px;
          height: 64px;
          background-color: $primary-color;
          color: white;
          font-weight: bold;
          font-size: 22px;
          border-radius: 5px;
          text-transform: uppercase;
          margin-top: 25px;
        }

        .asa-button {
          margin-top: 5px;
        }
      }
      .or-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 10px 0;
        p {
          margin: 0;
          font-size: 12px;
          font-weight: 400;
        }
        hr {
          height: 2px;
          color: #f4f4f4;
          flex: 1;
          margin: 0 10px;
        }
      }
      .signin-btn-container-asa {
        @include flex(column, flex-end, flex-end);
        @media only screen and (max-width: 769px) {
          justify-content: center;
        }
        .signin-button {
          cursor: pointer;
          border: none;
          width: 100%;
          height: 64px;
          border: #3627e4 1px solid;
          color: #3627e4;
          font-size: 22px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            margin-bottom: 0;
            margin-left: 30px;
          }
          img {
            object-fit: contain;
            height: 40%;
          }
        }
      }
    }

    .error {
      margin: 0;
      margin-top: 30px;
    }
    .success {
      margin: 0;
      margin-top: 30px;
    }
  }
  .outside-container {
    @include flex(row, space-evenly, center);
    color: white;
    margin-top: 25px;

    @media only screen and (max-width: 769px) {
      width: 90%;
      margin: auto;
    }

    .outside-btn {
      font-size: 12px;
      cursor: pointer;
      text-align: center;
      @media only screen and (max-width: 769px) {
        padding: 0 5px;
      }
    }

    .light {
      font-family: Akkurat-Light;
    }

    .no-decoration {
      color: white;
      text-decoration: none;
    }
  }
  .space-top {
    margin-top: 15px;
  }
  .space-bottom {
    margin-bottom: 15px;
  }

  .session-timeout-message-container {
    border: 1px solid red;
    border-radius: 5px;
    background-color: rgba(255, 0, 0, 0.2);
    margin-bottom: 5%;
    .session-timeout-message {
      color: red;
      padding: 10px;
      margin: 0;
    }
  }
}
