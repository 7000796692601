@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.support-content {
  padding-top: 7%;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 80vh;
  @include flex(column, flex-start, center);
  @media only screen and (max-width: 769px) {
    padding-top: 100px;
  }
  .support-header {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
  }

  .support-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media only screen and (max-width: 769px) {
      justify-content: center;
    }
  }

  .support-link {
    font-size: 1.5rem;
    height: 100px;
    width: 200px;
    margin: 15px 0;
    color: black;
    width: 20%;
    border: 3px solid rgba(128, 128, 128, 0.5);
    border-radius: 35px;
    margin-left: 2.5%;
    margin-right: 2.5%;
    text-decoration: none;
    text-align: center; // box-shadow: 0px 4px 23px rgb(0 0 0 / 8%);
    @include flex(row, center, center);
    @media only screen and (max-width: 769px) {
      width: 100%;
    }
  }
}
