@import '../../../reuse/mixins.scss';
@import '../../../reuse/variables.scss';

.goal-summary-container {
  padding: 15px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  .bar-chart {
    height: 40vh;
    padding: 15px 0;
  }
  .header {
    font-weight: bold;
    font-size: 25px;
  }
  .summary-container {
    padding: 15px;
    .row {
      display: flex;
      justify-content: space-between;
      .label {
        margin: 0;
        font-size: 15px;
        width: 70%;
      }
      .value {
        font-weight: bold;
        font-size: 15px;
      }
    }
  }
  .error {
    text-align: center;
    color: red;
  }

  .submit-btn {
    color: white !important;
    background: $goals-color !important;
    border-radius: 25px !important;
    width: 80% !important;
    margin: auto;
  }

  .bank-account-container {
    width: 60%;
    margin: 25px auto;
    .bank-account-label {
      font-size: 15px;
      text-align: center;
    }
    .link-plaid-container {
      @include flex(row, flex-end, center);
      margin: 15px 0 0 0;
    }
    .p-dropdown {
      width: 100%;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
