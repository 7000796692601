@import '../../../reuse/mixins.scss';
@import '../../../reuse/variables.scss';

.intro-container {
  margin: 25px 0;
  .activate-container {
    width: 100%;
    box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
      3px 1px 5px rgba(86, 86, 86, 0.2),
      inset 0px 0px 2px rgba(255, 255, 255, 0.21);
    border-radius: 19px;
    margin: 25px 0;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
    .top-info {
      padding: 15px;
      .heading {
        font-weight: bold;
        font-size: 20px;
        text-align: center;
      }
      .info {
        font-size: 15px;
        text-align: center;
      }
    }
    .start-button {
      border-radius: 0 0 25px 25px;
      background: black;
      cursor: pointer;
      .button-label {
        padding: 5px;
        color: white;
        font-size: 20px;
        text-align: center;
      }
    }
  }

  .strategy-info-container {
    width: 100%;
    box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
      3px 1px 5px rgba(86, 86, 86, 0.2),
      inset 0px 0px 2px rgba(255, 255, 255, 0.21);
    border-radius: 19px;
    padding: 15px;
    margin: 25px 0;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
    .name {
      font-weight: bold;
      font-size: 25px;
      color: $robo-color;
      text-align: center;
    }
    .description {
      font-size: 13px;
      text-align: center;
      font-weight: normal;
    }
    .disclaimer {
      color: rgba(128, 128, 128.4);
      text-align: right;
    }
    .specific-info {
      .row {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        .label {
          margin: 0;
          max-width: 50%;
        }
        .value {
          margin: 0;
        }

        .negative {
          color: red;
        }
      }
    }
  }
}
