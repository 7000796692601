@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';
.product-page {
  margin-top: 100px !important;
  .blue-background {
    background-color: $primary-color;
    color: white;
    .small-disclaimer-text {
      font-size: 10px;
      opacity: 50%;
    }
  }
  .grey-background {
    background-color: #f8f8f8;
  }
  .default-container {
    @include flex(row, space-between, center);
    max-width: 1440px;
    padding: 5%;
    height: 600px;
    margin: auto;
    @media screen and (max-width: 769px) {
      flex-direction: column;
      max-width: none;
      height: auto;
    }
  }
  .default-container-big {
    @include flex(row, space-between, center);
    max-width: 1440px;
    padding: 5%;
    height: 800px;
    margin: auto;
    @media screen and (max-width: 769px) {
      flex-direction: column;
      max-width: none;
      height: auto;
    }
  }
  .center-container {
    @include flex(column, center, center);
    max-width: 1440px;
    padding: 5%;
    height: 700px;
    margin: auto;
    @media screen and (max-width: 769px) {
      flex-direction: column;
      max-width: none;
      height: auto;
    }
  }
  .center-container-big {
    @include flex(column, center, center);
    max-width: 1440px;
    padding: 5%;
    height: auto;
    margin: auto;
    @media screen and (max-width: 769px) {
      flex-direction: column;
      max-width: none;
      height: auto;
    }
  }
  .center-container-small {
    @include flex(column, center, center);
    max-width: 1440px;
    padding: 5%;
    height: 500px;
    margin: auto;
    @media screen and (max-width: 769px) {
      flex-direction: column;
      max-width: none;
      height: auto;
    }
  }
  .column-container {
    @include flex(column, flex-start, center);
    max-width: 1440px;
    padding: 5%;
    margin: auto;
    @media screen and (max-width: 769px) {
      max-width: none;
      height: auto;
    }
  }
  .block {
    width: 50%;
    height: 100%;
    @include flex(column, space-evenly, flex-start);
    @media screen and (max-width: 769px) {
      width: 100%;
    }
    padding: 0 5%;
    &.blue-box {
      background-color: #3432df;
      color: white;
    }
    @media screen and (max-width: 769px) {
      padding: 5%;
    }
  }
  .disclaimer-section-background {
    background-color: #FAFAFA;
    width: 100%;
    @include flex(column, center, center);
    padding: 2.5% 0;
    .disclaimer-section {
      max-width: 1440px;
      margin: auto;
      font-size: 12px;
      padding: 0 5%;
      p:first-child{
        font-size: 14px;
      }
      p:last-child{
        color: #888;
      }
    }
    @media screen and (max-width: 769px) {
      padding: 5%;
    }
  }
  .center-block {
    width: 700px;
    @media screen and (max-width: 769px) {
      width: 100%;
      @include flex(column, center, center);
    }
  }
  .big-text {
    font-weight: 300;
    font-size: 68px;
    line-height: 72px;
    @media screen and (max-width: 769px) {
      font-size: 35px;
      line-height: 42px;
      margin-bottom: 15px;
    }
  }
  .medium-text {
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    @media screen and (max-width: 769px) {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 15px;
    }
  }
  .small-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    @media screen and (max-width: 769px) {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 15px;
    }
  }
  .small-top-text {
    font-size: 15px;
    line-height: 22px;
    vertical-align: top;
  }
  .tiny-text {
    font-size: 12px;
    line-height: 12px;
    @media screen and (max-width: 769px) {
      font-size: 8px;
      line-height: 10px;
      margin-bottom: 15px;
    }
  }
  .bold-text {
    font-family: 'Akkurat-Bold';
  }
  .light-text {
    font-family: 'Akkurat-Light';
  }
  .grey-color {
    color: #5e625f;
  }
  .dark-grey-color {
    color: #404541;
  }
  .phone-img-container-left {
    width: 100%;
    @include flex(row, flex-start, flex-start);
    @media screen and (max-width: 769px) {
      justify-content: center;
      margin-top: 25px;
    }
  }
  .phone-img-container-right {
    width: 100%;
    @include flex(column, flex-start, flex-end);
    .center-content {
      width: auto;
      @include flex(column, center, center);
      .chart-disclaimer-text {
        opacity: 50%;
        width: 180px;
        font-size: 10px;
        text-align: center;
      }
    }
    @media screen and (max-width: 769px) {
      justify-content: center;
      margin-top: 25px;
      width: 100%;
      .center-content {
        width: 100%;
      }
    }
  }
  .phone-img-container-center {
    width: 100%;
    @include flex(row, center, flex-start);
    @media screen and (max-width: 769px) {
      justify-content: center;
      margin-top: 25px;
    }
  }
  .phone-img {
    object-fit: contain;
    width: 250px;
    height: 500px;
    object-position: center;
    @media screen and (max-width: 769px) {
      height: 350px;
      width: 200px;
    }
  }
  .double-screen-img {
    object-fit: contain;
    width: 400px;
    height: 500px;
    @media screen and (max-width: 769px) {
      height: 200px;
      width: 300px;
    }
  }
  .assets-income-img {
    width: 100%;
    height: 500px;
    object-fit: contain;
    @media screen and (max-width: 769px) {
      height: 300px;
      width: 300px;
    }
  }
  .product-page-row {
    width: 100%;
    @include flex(row, space-between, center);
    @media screen and (max-width: 769px) {
      flex-direction: column;
    }
    .item {
      width: 50%;
      position: relative;
      @media screen and (max-width: 769px) {
        width: 100%;
      }
    }
    .item-small {
      width: 25%;
      position: relative;
      @media screen and (max-width: 769px) {
        width: 100%;
      }
    }
    .item-big {
      width: 65%;
      position: relative;
      @media screen and (max-width: 769px) {
        width: 100%;
      }
    }
    F .item-right {
      background-color: lightblue;
      width: 50%;
      @include flex(column, center, flex-end);
      @media screen and (max-width: 769px) {
        width: 100%;
        align-items: center;
      }
    }
    .item-right {
      width: auto;
      @include flex(column, center, flex-end);
    }
    .same-size {
      width: 250px;
      @media screen and (max-width: 769px) {
        width: 95%;
        margin-top: 20px;
      }
    }
  }
  .left-border {
    border-left: 25px solid $primary-color;
    padding-left: 5%;
  }
  .tools-img {
    width: 300px;
    height: 300px;
    object-fit: contain;
    @media screen and (max-width: 769px) {
      width: 200px;
      height: 200px;
    }
  }
  .tools-img-long {
    width: 300px;
    height: 400px;
    object-fit: contain;
    @media screen and (max-width: 769px) {
      width: 200px;
      height: 300px;
    }
  }
  .tools-disclaimer-text {
    opacity: 50%;
    width: 180px;
    font-size: 10px;
    text-align: center;
    align-self: center;
  }
  .space-top-big {
    margin-top: 10%;
    @media screen and (max-width: 769px) {
      margin-top: 30px;
    }
  }
  .capitalize {
    text-transform: capitalize;
  }
  .button {
    width: 100px;
    height: 45px;
    border-radius: 5px;
    color: white;
    background-color: $primary-color;
    border: none;
  }
  .features-container {
    width: 100%;
    margin: 15px 0;
  }
  .feature {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
    padding-bottom: 10px;
    width: 50%;
    &.white {
      border-bottom: 0.5px solid rgba(255, 255, 255, 1);
    }
  }
  .feature-label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.66);
    &.white {
      color: white;
    }
  }
  .padding {
    padding: 15px;
  }
  .padding-big {
    padding: 5%;
  }
  .graph {
    padding: 5%;
    height: 500px;

    .chart-disclaimer-text {
      opacity: 50%;
      margin-top: 15px;
      font-size: 10px;
      text-align: center;
      padding-bottom: 20px;
    }
    @media screen and (max-width: 769px) {
      height: 200px;
    }
  }
  .squeeze {
    width: 35%;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }
  p {
    margin: 0;
    padding: 0;
  }
  .final-amount {
    font-size: 35px;
  }
  .thin {
    font-family: 'Akkurat-Thin';
  }
  flex: 1;
  margin: 0;
  height: 100%;
  .slider-container {
    width: 100%;
  }
  input[type='range'] {
    height: 27px;
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: rgba(128, 128, 128, 0.5);
    border-radius: 5px;
    border: 0px solid #000000;
  }
  input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid black;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: $primary-color;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: rgba(128, 128, 128, 0.5);
  }
  input[type='range']::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: rgba(128, 128, 128, 0.5);
    border-radius: 5px;
    border: 0px solid #000000;
  }
  input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid black;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: $primary-color;
    cursor: pointer;
  }
  input[type='range']::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type='range']::-ms-fill-lower {
    background: black;
    border: 0px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type='range']::-ms-fill-upper {
    background: black;
    border: 0px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type='range']::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid black;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: $primary-color;
    cursor: pointer;
  }
  input[type='range']:focus::-ms-fill-lower {
    background: rgba(128, 128, 128, 0.5);
  }
  input[type='range']:focus::-ms-fill-upper {
    background: rgba(128, 128, 128, 0.5);
  }
  .space-bottom {
    margin-bottom: 15px;
  }
  .space-bottom-big {
    margin-bottom: 35px;
  }
}

.chart-disclaimer-text {
  opacity: 50%;
  margin-top: 15px;
  font-size: 10px;
  text-align: center;
  padding-bottom: 20px;
}
