.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    border-bottom: 1px solid $white;
    margin: 0 0 10px;
    padding: 0;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 0rem 1rem 0rem 1rem;
    cursor: pointer;
    font-size: 1.6rem;
}

.react-tabs__tab:hover {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 0rem 1rem 0rem 1rem;
    cursor: pointer;
    color: $theme-green;
    font-size: 1.6rem;
}

.react-tabs__tab--selected {
    background: $white;
    border-color: $white;
    color: $theme-green;
    border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
    color: $white;
    cursor: default;
}

.react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: $white;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}

.press-bar{
    padding: 1.6rem 0rem 1.3rem 1rem;
}

.press-title{
    font-size: 1.55rem;
    color: $theme-green;
}

.press-title:hover{
    font-size: 1.55rem;
    color: $theme-green;
    text-decoration: underline;
}

.press-date{
    color: #AAA;
    font-size: 0.85rem;
}

.press-content{
    color: $theme-grey;
}

.page-blank-row-md{
    padding: 6rem 0rem 0rem 0rem;
}

.page-blank-row-xs{
    padding: 4rem 0rem 0rem 0rem;
}
