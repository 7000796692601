@import "../../../reuse/mixins.scss";
@import "../../../reuse/variables.scss";

.summary-container {
  width: 100%;
  .header {
    font-size: 25px;
    font-weight: bold;
    margin: 25px 0;
  }
  .summary-row {
    display: flex;
    justify-content: space-between;
    .sumary-label {
      font-size: 20px;
    }
    .summary-value {
      font-weight: bold;
      font-size: 20px;
    }
  }
  .error-message {
    text-align: right;
    color: red;
  }
  .success-message {
    text-align: right;
    color: $robo-color;
  }
  .submit-button-container{
    width: 60%;
    margin: 25px auto;
      .submit-button{
          border-radius: 15px;
          color: white;
          background: $robo-color;
          width: 100%;
      }
  }
}
