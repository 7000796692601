@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.item-row {
  display: flex;
  align-items: center;
  margin: 15px 0;
  justify-content: space-between;
  cursor: pointer;
  .add-crypto-row-container {
    width: 100%;
    display: flex;
    .img-container {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 1px solid grey;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      .not-found {
      }
      .image {
        border-radius: 50%;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .info {
      width: 50%;
      .symbol {
        margin: 0;
        font-size: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .company {
        margin: 0;
        font-size: 12px;
        color: rgba(128, 128, 128, 0.9);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.p-inputtext {
  width: 100%;
}
