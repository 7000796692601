@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.apex-form-container {
  width: 100%;
  .input-container {
    margin: 15px 0;
    width: 100%;
    .input-label {
      font-size: 15px;
    }
    .input-sub-label {
      font-size: 12px;
      color: rgba(128, 128, 128, 1);
    }

    .spaced-input {
      margin: 10px 0;
    }

    .double-input {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .p-inputnumber {
        background: rgba(128, 128, 128, 0.1);
        width: 48%;
      }
      .p-inputtext {
        background: rgba(128, 128, 128, 0.1);
        width: 48%;
      }
    }
    .p-inputtext {
      background: rgba(128, 128, 128, 0.1);
      width: 100%;
    }
    .p-dropdown {
      background: rgba(128, 128, 128, 0.1);
      width: 100%;
    }
    .p-dropdown-trigger {
      background: rgba(128, 128, 128, 0.1);
    }
  }

  .disclosure {
    padding: 15px 0;
    .disclosure-header {
      font-size: 15px;
    }
    .disclosure-info {
      font-size: 12px;
      color: rgba(128, 128, 128, 1);
    }
    .radio-input-row {
      display: flex;
      .radio-input-container {
        display: flex;
        .radio-input-label {
          margin: 0 25px 0 5px;
        }
        .p-radiobutton .p-radiobutton-box.p-highlight {
          border-color: $primary-color;
        }
        .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
          background: $primary-color;
        }
      }
    }
  }

  .agreement-row {
    display: flex;
    margin: 10px 0;
    .agreement-label {
      margin: 0 0 0 15px;
      span {
        text-decoration: underline;
        font-family: Akkurat-Bold;
      }
    }
    .p-checkbox .p-checkbox-box.p-highlight {
      border-color: $primary-color;
      background: $primary-color;
    }
  }
  .submit-btn-container {
    margin: auto;
    width: 80%;
    padding: 15px 0;
    .submit-btn {
      width: 100%;
      border-radius: 10px;
    }
  }

  .success-msg {
    text-align: center;
    color: $primary-color;
  }
  .error-msg {
    text-align: center;
    color: $negative-color;
    margin-top: 15px;
  }
}
