@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.statements-container {
  margin: 50px 0;
  box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
    3px 1px 5px rgba(86, 86, 86, 0.2),
    inset 0px 0px 2px rgba(255, 255, 255, 0.21);
  border-radius: 19px;
  .trade-confirmation-container {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 70vh;
    .row {
      padding: 15px;
      .statement-link {
        color: black;
        text-decoration: none;
      }
    }
  }
  .account-statements-container {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 70vh;
    .row {
      padding: 15px;
      .statement-link {
        color: black;
        text-decoration: none;
      }
    }
  }
  .tax-documents-container {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 70vh;
    .row {
      padding: 15px;
      .statement-link {
        color: black;
        text-decoration: none;
      }
    }
  }
  .p-tabview .p-tabview-nav {
    background-color: transparent;
    .p-tabview .p-tabview-nav {
      background-color: transparent;

      li .p-tabview-nav-link {
        background-color: transparent;
        background: transparent;
      }
    }

    li .p-tabview-nav-link {
      background: transparent !important;
      border: none;
    }
  }

  .p-tabview-panels {
    background-color: transparent;
    background: transparent !important;
    height: 100%;
  }
}
