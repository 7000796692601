@import "../../../reuse/mixins.scss";
@import "../../../reuse/variables.scss";

.non-product-basic-info-container {
  text-align: center;
  padding: 50px 0;
  .header-container {
    .header {
      font-family: Akkurat-Bold;
      font-size: 16px;
      margin: 0;
    }
    .positive{
      color: $primary-color;
    }
    .negative{
      color: $negative-color;
    }
    .total-value {
      font-size: 43px;
      font-family: Akkurat-Light;
      margin: 0;
    }
  }
  .details-container {
    border-bottom: 0.25px solid rgba(0, 0, 0, 0.1);
    border-top: 0.25px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    .details-content {
        width: 60%;
        margin: auto;
      .details-row {
        display: flex;
        justify-content: space-between;
      }
      .first{
          margin-bottom: 15px;
      }
      .details-label {
        margin: 0;
      }
      .details-value {
        margin: 0;
      }
    }
  }
}
