@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';

.credit-card {
  @include flex(column, center, center);
  cursor: pointer;
  position: relative;
  height: 142.5px;
  .blue-dot {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background-color: $primary-color;
    border-radius: 50%;
    @include flex(row, center, center);

    .blue-dot-i {
      font-size: 10px;
      margin: 0;
      color: white;
    }
  }

  .exclamation {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .credit-header {
    font-family: Akkurat-Bold;
    font-size: 20px;
  }

  .big-price {
    font-family: Akkurat-Light;
    font-size: 30px;
    color: #3627e4;
    margin-left: 15px;
  }

  .credit-row {
    @include flex(row, center, center);
    .credit-row-center {
      @include flex(row, center, center);

      .credit-card-label {
        font-size: 15px;
        margin: 0 0 0 15px;
      }
    }
  }

  .credit-message {
    margin: 0;
    color: rgba(128, 128, 128, 0.8);
    font-size: 12px;
  }
  .credit-text {
    font-size: 18px;
    font-family: 'Akkurat-Bold';
    margin: 0;
  }
}
