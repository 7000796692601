@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.unremoveable-header-container {
  text-align: center;
  .unremoveable-header {
    font-size: 25px;
  }
  .unremoveable-signout {
    position: absolute;
    right: 0;
    top: 0;
    margin: 25px;
    font-size: 15px;
    cursor: pointer;

    @media screen and (max-width: 769px) {
      position: relative;
      margin: 0;
    }
  }
}
.extended-hours-agreement-container {
  .agreement-row {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    .agreement-label {
      margin: 0 0 0 15px;
      span {
        text-decoration: underline;
        font-family: Akkurat-Bold;
      }
    }
    .p-checkbox .p-checkbox-box.p-highlight {
      border-color: $primary-color;
      background: $primary-color;
    }
  }
  .button-container {
    display: flex;
    margin: 10px 0;
    gap: 20px;
    width: 100%;
    justify-content: center;
    .dw-apex-submit-btn {
      border-radius: 25px;
      background: $primary-color;
      color: white;
      padding: 10px 30px;
    }
  }
}
