@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';
@import url('../components/landingPage.scss');

.infoSection__small {
  height: 70vh;
  max-height: 720px;
  width: 100%;
  @include flex(column, center, center);
}

.infoSection__small.gray {
  background-color: #f4f4f4;
}

.infoSection__reg {
  height: 90vh;
  max-height: 1080px;
  width: 100%;
  @include flex(column, center, center);
  z-index: 0;
}
.infoSection__reg.green {
  background-color: #3a7155;
}
.infoSection__reg.darkgreen {
  background-color: #3b5f45;
}
.infoSection__reg.dark {
  background-color: rgb(10, 9, 30);
}
.infoSection__reg.black {
  background-color: #000000;
}
.infoSection__reg.gray {
  background-color: #f4f4f4;
}
.infoSection__reg.pink {
  background-color: #fdf5f6;
}

.infoSection__large {
  height: 135vh;
  max-height: 3840px;
  @include flex(column, center, center);
}

.infoSection__large.pink {
  background-color: #fdf5f6;
}
.infoSection__large.black {
  background-color: #000000;
}

.title-container {
  width: 100%;
  padding: 0 9%;
  margin-top: 50px;
}

.fullRow__container {
  height: 100%;
  width: 100%;
  @include flex(row, center, center);
  flex: 1;
  overflow: hidden;
  .text__container {
    ul {
      padding-left: 14px;
      //   list-style: none;
    }
    ol {
      padding-left: 20px;
    }
  }
}

.fullRow__container.even {
  .small__wrapper {
    width: 1080px;
    @include flex(row, center, center);
    height: 50%;
  }
  .image__container {
    flex: 1;
    height: 100%;
    @include flex(column, center, center);
    img {
      height: 80%;
      margin-right: 5%;
      max-width: 400px;
      object-fit: contain;
    }
  }
  .text__container {
    flex: 1;
    height: 100%;
    @include flex(column, center, center);
    .wrapper__container {
      max-width: 500px;
      @include flex(column, center, flex-start);
      p {
        margin-bottom: 5%;
      }
    }
    .wrapper__container.white {
      color: white;
      @include flex(column, center, flex-start);
      max-width: 500px;
    }
    &.textOnly {
      margin-top: 20%;
      max-width: 25%;
      @include flex(column, flex-start, center);
      &:not(:last-child) {
        margin-right: 50px;
      }
    }
  }
}

.fullRow__container.smallLeft {
  .image__container {
    flex: 2;
    height: 100%;
    @include flex(column, center, flex-end);
    img {
      max-height: 80%;
      margin-right: 5%;
      object-fit: contain;
    }
  }
  .text__container {
    flex: 3;
    height: 100%;
    margin-left: 5%;
    @include flex(column, center, flex-start);
    .wrapper__container {
      @include flex(column, center, flex-start);
      max-width: 500px;
    }
    .wrapper__container.white {
      color: white;
      @include flex(column, center, flex-start);
      max-width: 500px;
    }
  }
}
.fullRow__container.smallRight {
  .image__container {
    width: 50%;
    height: 100%;
    @include flex(column, center, flex-start);
    img {
      max-height: 80%;
      margin-right: 5%;
    }
  }
  .text__container {
    width: 500px;
    height: 100%;
    @include flex(column, center, flex-end);
    .wrapper__container {
      @include flex(column, center, flex-start);
      max-width: 500px;
    }
    .wrapper__container.white {
      color: white;
      max-width: 500px;
    }
  }
}
.fullRow__container.smallRight {
  .image__container {
    flex: 1;
    height: 100%;
    @include flex(column, center, center);
    img {
      height: 80%;
      margin-right: 5%;
    }
  }
  .text__container {
    flex: 2;
    height: 100%;
    margin-left: 5%;
    @include flex(column, center, center);
    .wrapper__container {
      @include flex(column, center, flex-start);
      max-width: 500px;
    }
    .wrapper__container.white {
      color: white;
      @include flex(column, center, flex-start);
      max-width: 500px;
    }
  }
}

.info__container__mobile {
  width: 100%;
  padding: 50px 20px 20px 20px;
  @include flex(column, flex-start, flex-start);
  .image__container {
    width: 100%;
    @include flex(column, center, center);
    img {
      width: 100%;
      object-fit: contain;
    }
    &.smaller-img {
      width: 50%;
      @include flex(column, center, center);
      align-self: center;
    }
  }
  ol {
    padding-left: 20px;
  }
  ul {
    padding-left: 20px;
  }
}

.info__container__mobile.white {
  color: white;
}
.info__container__mobile.greenBackground {
  color: white;
  background-color: #3a7155;
}
.info__container__mobile.blackBackground {
  color: white;
  background-color: #000000;
}
.info__container__mobile.darkBackground {
  color: white;
  background-color: rgb(10, 9, 30);
}
.info__container__mobile.gray {
  background-color: #f4f4f4;
}
.info__container__mobile.pinkBackground {
  background-color: #fdf5f6;
}

.aboutMain__container {
  min-height: 100vh;
  width: 100%;
  @include flex(column, flex-start, center);
  .wrapper__container {
    width: 100%;
    max-width: 1920px;
    padding: 10% 200px;
    ul {
      margin: 0;
      padding: 0;
      padding-left: 20px;
    }
    .section {
      width: 75%;
      margin: 25px 0;
    }
  }
  @media screen and (max-width: 1000px) {
    .wrapper__container {
      padding: 100px 30px;
    }
  }
}

// Rework Code
.info-full {
  width: 100%;
  height: 90vh;
  max-height: 1080px;
  @include flex(column, center, center);
  &.green {
    color: white;
    background-color: #3a7155;
  }
  &.black {
    color: white;
    background-color: #000000;
  }
  .content-wrapper {
    width: 100%;
    max-width: 1366px;
    height: 100%;
    font-size: 18px;
    .text-with-image {
      width: 100%;
      height: 100%;
      @include flex(row, center, center);
      &.left {
        .left-side {
          flex: 2;
          height: 100%;
          @include flex(column, center, center);
          img {
            height: 80%;
          }
        }
        .right-side {
          flex: 2;
          height: 100%;
          @include flex(column, center, flex-start);
          text-align: left;
          p {
            width: 60%;
          }
        }
      }
      &.right {
        .left-side {
          flex: 2;
          height: 100%;
          @include flex(column, center, flex-end);
          text-align: left;
          padding-right: 10%;
          p {
            width: 60%;
          }
        }
        .right-side {
          flex: 2;
          height: 100%;
          @include flex(column, center, flex-start);
          img {
            height: 80%;
          }
        }
      }
      @media screen and (max-width: 768px) {
        @include flex(column, center, center);
        &.left {
          .left-side {
            height: 20%;
            width: 100%;
            @include flex(column, center, center);
            img {
              height: 80%;
              object-fit: contain;
            }
          }
          .right-side {
            flex: 2;
            height: 100%;
            @include flex(column, center, flex-start);
            text-align: left;
            p {
              width: 60%;
            }
          }
        }
      }
    }
  }
}

.crypto-hero-container {
  width: 100%;
  height: 75vh;
  max-width: 1440px;
  padding: 0 100px;
  max-height: 1080px;
  @include flex(row, center, center);
  @media screen and (max-width: 1000px) {
    padding: 0;
  }
  .text-side {
    @include flex(column, center, flex-start);
    height: 100%;
    flex: 2;
    .crypto-main-title {
      font-size: 77px;
      font-family: 'Akkurat-Light';
      margin: 0;
      line-height: 98px;
      letter-spacing: -2px;
      margin-bottom: 20px;
      @media screen and (max-width: 1366px) {
        font-size: 36px;
        line-height: 30px;
      }
    }
    .crypto-subtext {
      margin-bottom: 40px;
      width: 550px;
      font-size: 23px;
      font-family: 'Akkurat-Light';
    }
    @media screen and (max-width: 1000px) {
      width: 100%;
      padding: 0 25px;
      .crypto-main-title {
        font-size: 40px;
        line-height: 44px;
        font-family: 'Akkurat';
      }
      .crypto-subtext {
        width: 100%;
        font-size: 17px;
      }
    }
  }
  .image-side {
    flex: 1;
    height: 100%;
    @include flex(column, center, center);
    img {
      margin-top: 25%;
      width: 100%;
      object-fit: contain;
    }
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
}

.data-container {
  width: 100%;
  @include flex(column, center, center);
}

.crypto-title {
  width: 80%;
  @include flex(row, center, center);
  padding-top: 75px;
  text-align: center;
  @media screen and (max-width: 1000px) {
    padding-top: 25px;
  }
}

.crypto-table {
  width: 100%;
  @include flex(column, center, center);
  .wrapper-container {
    @include flex(column, center, center);
    width: 100%;
    max-width: 1920px;
    padding: 0 200px;
    @media screen and (max-width: 1000px) {
      padding: 0px;
    }
  }
}

.table-disclaimer-text {
  margin-top: 15px;
  font-size: 10px;
  text-align: center;
  opacity: 50%;
  @media screen and (max-width: 1000px) {
    width: 80%;
  }
}

.spacer {
  height: 10vh;
  width: 100%;
}
