@import url('//hello.myfonts.net/count/3f4e96');
@import url('./scss/components/landingPage.scss');
@import url('./scss/components/button.scss');
@import url('./scss/components/general.scss');
@import url('./scss/components/infoPages.scss');
@import url('./scss/components/analytics.scss');
@import url('./scss/components/footer.scss');
@import url('./scss/pages/disclosures.scss');
@import url('./scss/pages/account-page.scss');
// Import fonts
// @font-face {
//   font-family: "HelveticaLTWXX-Roman";
//   src: url("./assets/fonts/HelveticaLTWXXRoman.woff2") format("woff2"),
//     url("./assets/fonts/HelveticaLTWXXRoman.woff") format("woff");
// }
// @font-face {
//   font-family: "ProximaNova";
//   src: url("./assets/fonts/ProximaNova-Regular.otf") format("opentype");
// }
// @font-face {
//   font-family: "ProximaNova-Bold";
//   src: url("./assets/fonts/ProximaNova-Bold.otf") format("opentype");
// }
// @font-face {
//   font-family: "ProximaNova-ExtraBold";
//   src: url("./assets/fonts/ProximaNova-Extrabold.otf") format("opentype");
// }
// Regular Font
@font-face {
    font-family: 'Akkurat';
    src: url('./assets/fonts/Akkurat/AkkuratLL-Regular.otf') format('opentype'), url('./assets/fonts/Akkurat/AkkuratLLTT-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// Bold Font
@font-face {
    font-family: 'Akkurat';
    src: url('./assets/fonts/Akkurat/AkkuratLL-Black.otf') format('opentype'), url('./assets/fonts/Akkurat/AkkuratLLTT-Black.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

// Slightly Less Bold
@font-face {
    font-family: 'Akkurat-Bold';
    src: url('./assets/fonts/Akkurat/AkkuratLL-Bold.otf') format('opentype'), url('./assets/fonts/Akkurat/AkkuratLLTT-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

//Thin Font
@font-face {
    font-family: 'Akkurat-Thin';
    src: url('./assets/fonts/Akkurat/AkkuratLL-Thin.otf') format('opentype'), url('./assets/fonts/Akkurat/AkkuratLLTT-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// Light Font
@font-face {
    font-family: 'Akkurat-Light';
    src: url('./assets/fonts/Akkurat/AkkuratLL-Light.otf') format('opentype'), url('./assets/fonts/Akkurat/AkkuratLLTT-Light.ttf') format('truetype');
    font-weight: lighter;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Akkurat';
}


/* ===== Scrollbar CSS ===== */


/* Firefox */

* {
    scrollbar-width: auto;
    scrollbar-color: rgba(128, 128, 128, 0.5) white;
}


/* Chrome, Edge, and Safari */

*::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.content {
    padding: 2% 0 0 0;
}

.dropdown-menu.show {
    margin-left: 5% !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    // background: rgba(63, 70, 246, 0.5) !important;
    background: rgba(128, 128, 128, 0.5) !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background-color: #3f46f6 !important;
}

.p-dialog .p-dialog-header {
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
}

.p-dialog .p-dialog-content {
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.rejectButton {
    display: none;
}

.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
    top: 8px !important;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.shadow-card {
    background-color: white;
    border-radius: 19px;
    padding: 25px;
    box-shadow: rgb(255 255 255 / 92%) -3px -2px 4px, rgb(86 86 86 / 20%) 3px 1px 5px, rgb(255 255 255 / 21%) 0px 0px 2px inset;
    width: 100%;
    position: relative;
    @media screen and (max-width: 769px) {
        padding: 25px 15px;
    }
}

.space-top {
    margin-top: 15px;
}

.space-top-big {
    margin-top: 30px;
}

.space-bottom {
    margin-bottom: 15px;
}

.space-bottom-big {
    margin-bottom: 30px;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.account-card {
    margin-bottom: 30px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    position: relative;
    box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2), inset 0px 0px 2px rgba(255, 255, 255, 0.21);
    border-radius: 19px;
}

.clickable {
    cursor: pointer;
}