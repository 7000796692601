@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.dialog-header {
  font-size: 20px;
  text-align: center;
  margin-left: 30px;
}

.confirm-dialog-submit-btn {
  border-radius: 15px !important;
  color: white !important;
  background: $primary-color !important;
  display: block;
  margin: 0 auto;
  &.robo {
    background: $robo-color !important;
  }
  &.group {
    background: $group-color !important;
  }
  &.goals {
    background: $goals-color !important;
  }
}

.error {
  text-align: center;
  color: red;
}
.success {
  text-align: center;
  color: $primary-color;
}

.message {
  text-align: center;
}
