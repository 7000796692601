@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.finra-container {
  .question-container {
    margin: 25px 0;
    .question-header {
      font-size: 20px;
    }
    .question-input-container {
      .option-container {
        display: flex;
        .option-label {
          font-size: 15px;
          margin-left: 15px;
        }
      }
    }
    .p-dropdown {
      width: 50%;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
      border-color: $primary-color;
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
      background-color: $primary-color;
    }
  }
  .finra-submit-container {
    width: 50%;
    margin: auto;
    .finra-submit-btn {
      width: 100%;
      border-radius: 25px;
      background: $primary-color;
      color: white;
    }
  }
  .error-msg {
    color: red;
    text-align: center;
    margin-top: 15px;
  }
  .success-msg {
    color: $primary-color;
    text-align: center;
    margin-top: 15px;
  }
}
