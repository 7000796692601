@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';
.external-accounts-card {
  position: relative;
  cursor: pointer;
  .external-accounts-row {
    @include flex(row, center, center);
    .external-accounts-title {
      font-family: Akkurat-Bold;
      font-size: 20px;
      margin: 0;
    }
    .external-accounts-right-arrow {
      position: absolute;
      right: 0;
      height: 18px;
      width: 12px;
    }
    .external-accounts-down-arrow {
      position: absolute;
      right: 0;
      width: 18px;
      height: 12px;
    }
    .external-accounts-group {
      display: flex;
      align-items: center;

      .plus-icon {
        width: 30px;
        height: 30px;
        margin-left: 15px;
      }
    }
  }
}
