@import '../../../reuse/mixins.scss';
@import '../../../reuse/variables.scss';

.non-prod-pending-transfers-container {
  margin: 50px 0;
  .non-prod-pending-transfers-header {
    font-family: Akkurat-Bold;
    font-size: 16px;
    margin: 0;
    text-align: center;
  }
  .non-prod-pending-transfers-list {
    // padding: 15px;
    // background: #f6f6f6;
    // box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
    //   3px 1px 5px rgba(86, 86, 86, 0.2),
    //   inset 0px 0px 2px rgba(255, 255, 255, 0.21);
    // border-radius: 19px;
  }
}
