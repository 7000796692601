@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';

.notifications-space-top {
  margin-top: 15px;
  border: 1px solid $primary-color;
}
.notifications-circle {
  @include flex(row, center, center);
  background-color: $primary-color;
  width: 35px;
  height: 35px;
  position: absolute;
  top: -15px;
  right: 15px;
  border-radius: 50%;
  .notifications-count {
    color: white;
    font-size: 12px;
    margin: 0;
  }
}
.notifications-card {
  position: relative;
  cursor: pointer;

  .notifications-card-header {
    font-size: 17px;
    font-family: 'Akkurat-Bold';
    text-align: left;
  }

  .notifications-card-info {
    font-size: 20px;
    font-family: 'Akkurat-Bold';
    text-align: center;
    margin: 0;
  }
}
