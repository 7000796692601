@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.options-container {
  .options-sub-header {
    font-size: 20px;
    text-align: center;
  }
  .options-row {
    display: flex;
    margin: 25px 0;
    justify-content: space-evenly;
    .options-container {
      display: flex;
      justify-content: center;
      .option-label {
        margin: 0;
      }
      .p-radiobutton .p-radiobutton-box.p-highlight {
        border-color: $primary-color;
      }
      .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background: $primary-color;
      }
    }
  }
  .options-submit-btn-container {
    display: flex;
    justify-content: center;
    .options-submit-btn {
      border-radius: 25px;
      background: $primary-color;
      color: white;
    }
  }
  .have-code-container {
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
    font-size: 13px;
  }
}
.vi__wrapper {
  display: flex;
  justify-content: center;
}
.verificationInput__container {
  height: 50px;
  width: 300px;
  margin-bottom: 20px;
}
.verificationInputCharacter__container {
  line-height: 50px;
  font-size: 36px;
  color: black;
  border-bottom: 2px solid black;
  &:not(:first-child) {
    margin-left: 8px;
  }
}
.verificationInputCharacter__container__inactive {
  border-bottom: 2px solid lightgrey;
}
.verificationInputCharacter__container__selected {
  border-bottom: 2px solid #3181ff;
}
