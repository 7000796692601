@import '../../../scss/reuse/variables.scss';
@import '../../../scss/reuse/mixins.scss';

.landing-page {
  width: 100vw;
  max-width: 100%;
  background: #f6f6f6;
  .main-screen {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-image: url('../../../assets/main-screen-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media only screen and (max-width: 769px) {
      height: 90vh;
    }
    .content {
      width: 100%;
      height: 100%;
      max-width: 1440px;
      padding: 10% 5%;
      max-height: 1200px;
      min-height: 100vh;
      @include flex(column, center, flex-start);
      @media only screen and (max-width: 769px) {
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        padding: 18px;
      }
      .info-container {
        position: relative;
        margin-bottom: 10%;
        @media only screen and (max-width: 769px) {
          margin-bottom: 0;
        }
        .title {
          color: white;
          font-size: 5rem;
          font-family: Akkurat-Light;
          line-height: 86px;
          text-align: left;
          max-width: 650px;
          @media only screen and (max-width: 769px) {
            font-size: 43px;
            line-height: 44px;
            font-family: Akkurat;
            font-weight: normal;
            letter-spacing: -0.04em;
          }
        }

        .info {
          color: white;
          font-size: 25px;
          line-height: 31px;
          font-family: Akkurat-Light;
          width: 60%;
          margin-top: 5%;

          @media only screen and (max-width: 769px) {
            width: 100%;
            font-size: 18px;
            line-height: 22px;
            font-family: Akkurat-Light;
            letter-spacing: -0.03em;
          }
        }
      }

      .get-started-container {
        position: relative;
        width: 100%;
        margin-bottom: 25px;
        max-width: 620px;
        @include flex(row, space-between, flex-start);

        @media only screen and(max-width: 769px) {
          flex-direction: column;
        }

        .email-input {
          width: 100%;
          border: none;
          margin-bottom: 10px;
          border-radius: 5px;
          padding: 15px 15px;
          height: 64px;
          font-size: 21px;
          background-color: white;

          @media only screen and(max-width: 769px) {
            width: 100%;
          }
        }

        ::placeholder {
          color: rgba(39, 39, 72, 0.48);
        }

        .signup-btn {
          width: 100%;
          background-color: $primary-color;
          height: 64px;
          border-radius: 35px;
          border: none;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: white;
          font-weight: bold;
          font-size: 23px;

          @media only screen and(max-width: 769px) {
            width: 100%;
            border-radius: 35px;
          }
        }

        .get-the-app-icons {
          width: 100%;
          @media only screen and (max-width: 769px) {
            @include flex(column, center, center);
          }
          .store-icons {
            width: 100%;
            margin: 15px 0;
            @include flex(row, space-evenly, center);
            a {
              @include flex(row, center, center);
            }
            .app-store-icon {
              width: 95%;
            }
            .play-store-icon {
              width: 95%;
            }
          }
          .apk-icon {
            width: 100%;
            @include flex(row, center, center);
            a {
              @include flex(row, center, center);
              width: 100%;
            }
            .apk-download-icon {
              width: 50%;
            }
          }
        }

        .text-btn {
          color: white;
          font-size: 15px;
          margin-bottom: 0;
          margin-top: 5px;
          text-align: center;

          width: 100%;
        }

        .bold-text {
          font-family: Akkurat-Bold;
        }
      }

      .get-the-app-container {
        position: relative;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 25px;
        width: 100%;
        @media only screen and (max-width: 769px) {
          text-align: center;
          margin: 0;
        }
        .get-the-app-btn {
          border: none;
          border-radius: 35px;
          color: white;
          background: $primary-color;
          font-size: 26px;
          font-family: Akkurat-Bold;
          text-transform: uppercase;
          width: 265px;
          height: 68px;
          margin-bottom: 30px;
          @media only screen and (max-width: 769px) {
            font-size: 13px;
            width: 100%;
            height: 38px;
            margin: 15px auto;
          }
        }
        .get-the-app-icons {
          display: flex;
          flex-wrap: wrap;
          .app-store-icon {
            width: 200px;
            margin-bottom: 5px;
          }
          .play-store-icon {
            width: 200px;
            margin: 0 10px 5px 10px;
          }
          .apk-download-icon {
            width: 200px;
            margin: 0 10px 5px 10px;
          }
          .notice-text {
            font-size: 10px;
            color: white;
            text-align: center;
          }
        }
      }
    }
  }

  .download-page {
    height: 80vh;
    padding-top: 50px;
    padding-bottom: 35px;
    @include flex(column, flex-start, center);
    .download-icons-container {
      @include flex(column, center, center);
      height: 100%;
      .download-header-container {
        margin-bottom: 25px;
        @include flex(row, center, center);
        .download-header {
          font-family: Akkurat-Bold;
          font-size: 30px;
          margin: 0;
          line-height: 27px;
          margin-right: 15px;
        }
      }
      .download-text {
        font-size: 18px;
        line-height: 22px;
        padding: 0 15px;
        text-align: center;
      }
      .icons-container {
        height: 100%;
        @include flex(column, space-evenly, center);
        a {
          @include flex(row, center, center);
          .download-icon {
            margin: 10px 0;
            width: 234px;
            height: 75px;
          }
        }
      }
    }
    .notice-text-small {
      text-align: center;
      font-size: 12px;
    }
  }

  .product-screen {
    height: auto;
    width: 100%;
    background: linear-gradient(180deg, #4d54f9 0%, #5f55eb 90.62%);
    padding: 5%;
    @include flex(row, space-between, flex-start);
    @media only screen and (max-width: 769px) {
      height: auto;
      @include flex(column, space-between, center);
    }

    .product-info {
      width: 100%;
      height: 100%;
      max-width: 1440px;
      margin: auto;
      @include flex(row, space-evenly, flex-start);
      @media only screen and (max-width: 769px) {
        flex-direction: column;
        width: 100%;
      }

      .product-left {
        width: 50%;
        height: 100%;
        @include flex(row, center, center);
        @media only screen and (max-width: 769px) {
          width: 100%;
        }
        .phone-img-container {
          @include flex(row, center, center);
          width: 100%;
          height: 100%;
          .phone-img {
            object-fit: contain;
            height: 100%;
            max-height: 600px;
            width: 100%;
          }
        }
      }

      .product-right {
        width: 50%;
        height: 100%;
        padding-top: 2%;
        @media only screen and (max-width: 769px) {
          width: 100%;
        }
        .product-title {
          color: white;
          font-family: Akkurat-Light;
          font-weight: lighter;
          font-size: 103px;
          line-height: 91px;
          margin-bottom: 10%;
          min-height: 200px;
          @media only screen and (max-width: 769px) {
            font-size: 51px;
            line-height: 55px;
            margin: 65px 0;
            margin-top: 35px;
            min-height: 100px;
          }
        }

        .fade1 {
          animation: fadeIn1 1s;
        }
        .fade2 {
          animation: fadeIn2 1s;
        }
        .fade3 {
          animation: fadeIn3 1s;
        }
        .fade4 {
          animation: fadeIn4 1s;
        }

        @keyframes fadeIn1 {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes fadeIn2 {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes fadeIn3 {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes fadeIn4 {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .products {
          width: 100%;
          margin-bottom: 25px;
          @media only screen and (max-width: 769px) {
            margin-bottom: 25px;
          }
          .tabs {
            @include flex(row, space-between, center);
            width: 100%;
            border-bottom: 0.25px solid #fcddec;
            .tab {
              font-family: Akkurat-Bold;
              font-size: 21px;
              cursor: pointer;
              color: #a5a6f6;
              padding-bottom: 5px;
              line-height: 50px;
              text-transform: uppercase;
              margin: 0;
              @media only screen and (max-width: 769px) {
                font-size: 12px;
                line-height: 30px;
              }
            }
            .active {
              position: relative;
              color: white;
              z-index: 0;
            }
            .active:after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              background: #e2ddff;
              height: 4px;
              width: 100%;
              margin: 0 1px;
              border-radius: 4px;
            }
          }

          .tab-content-container {
            width: 100%;
            .tab-content {
              color: #ffffff;
              font-size: 25px;
              line-height: 32px;
              font-family: Akkurat-Light;
              margin-top: 25px;
              width: 80%;

              @media only screen and (max-width: 769px) {
                font-size: 13px;
                line-height: 18px;
              }
            }
          }

          .learn-more-btn-container {
            padding-top: 50px;
            .learn-more-btn {
              background: #ffffff;
              border-radius: 140px;
              border: none;
              height: 57px;
              width: 211px;
              color: $primary-color;
              font-size: 19px;
              font-weight: bold;

              @media only screen and (max-width: 769px) {
                height: 29px;
                font-size: 12px;
                width: 106px;
              }
            }
          }

          .phone-img-container {
            @include flex(row, center, center);
            margin-top: 25px;
            height: 100%;
            width: 100%;
            .phone-img {
              height: 100%;
              width: 100%;
              object-fit: contain;

              @media only screen and (max-width: 769px) {
                max-height: 400px;
              }
            }
          }
        }
      }
    }
  }

  .automated-precision {
    height: auto;
    width: 100%;
    padding-top: 5%;
    @media only screen and (max-width: 769px) {
      height: auto;
      margin: 25px 0;
      padding: 0 15px;
    }
    hr {
      width: 90%;
      margin: auto;
    }
    @include flex(column, center, center);
    .automated-header {
      font-size: 4.5rem;
      font-weight: lighter;
      font-family: Akkurat-Light;
      line-height: 81px;
      text-align: center;
      height: 30%;
      padding-bottom: 1.5rem;
      @include flex(column, center, center);
      @media only screen and (max-width: 769px) {
        font-size: 39px;
        line-height: 40px;
        text-align: center;
        margin: 35px 0;
      }
    }
    .automated-content {
      height: 70%;
      max-width: 1440px;
      @include flex(row, center, center);
      @media only screen and (max-width: 769px) {
        flex-direction: column;
        text-align: center;
        align-items: center;
      }
      width: 100%;
      .left {
        width: 35%;
        margin-right: 3%;
        height: 100%;
        padding-bottom: 3%;
        @include flex(column, space-evenly, center);
        @media only screen and (max-width: 769px) {
          width: 100%;
          margin-right: 0;
          justify-content: center;
          align-items: center;
        }
        .budgeting-img {
          width: 100%;
          max-width: 500px;
        }
        .bottom-content {
          padding: 15px 0 0 10px;
          .header {
            font-family: Akkurat-Bold;
            font-size: 1.5rem;
            line-height: 27px;
            text-transform: capitalize;

            @media only screen and (max-width: 769px) {
              padding: 5px;
              font-size: 25px;
              line-height: 13px;
              margin-top: 15px;
              text-align: center;
            }
          }
          .description {
            font-size: 18px;
            line-height: 25px;

            @media only screen and (max-width: 769px) {
              padding: 0 5px;
              font-size: 18px;
              line-height: 25px;
              text-align: center;
              margin: auto;
              width: 80%;
            }
          }
          .learn-more-btn {
            border: none;
            background-color: $primary-color;
            color: white;
            width: 189px;
            height: 48px;
            font-size: 17px;
            font-weight: bold;
            border-radius: 35px;

            @media only screen and (max-width: 769px) {
              width: 145px;
              height: 40px;
              font-size: 12px;
              padding: 0 5px;
            }
          }
        }
      }
      .right {
        width: 35%;
        margin-left: 3%;
        height: 100%;
        padding-bottom: 3%;
        @include flex(column, space-evenly, center);
        @media only screen and (max-width: 769px) {
          width: 100%;
          margin-left: 0;
          justify-content: center;
          align-items: center;
          margin-top: 50px;
        }
        .crypto-trader-img {
          width: 100%;
          max-width: 500px;
        }
        .bottom-content {
          padding: 15px 0 0 10px;
          .header {
            font-family: Akkurat-Bold;
            font-size: 1.5rem;
            line-height: 27px;

            @media only screen and (max-width: 769px) {
              padding: 5px;
              font-size: 25px;
              line-height: 13px;
              margin-top: 15px;
              text-align: center;
            }
          }
          .description {
            font-size: 18px;
            line-height: 25px;

            @media only screen and (max-width: 769px) {
              padding: 0 5px;
              font-size: 18px;
              line-height: 25px;
              margin-bottom: 50px;
              text-align: center;
              margin: auto;
              width: 80%;
            }
          }
          .learn-more-btn {
            border: none;
            background-color: $primary-color;
            color: white;
            width: 189px;
            height: 48px;
            font-size: 17px;
            font-weight: bold;
            border-radius: 35px;

            @media only screen and (max-width: 769px) {
              width: 145px;
              padding: 0 5px;
              height: 40px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .carousel-items {
    padding: 2% 5%;
    height: auto;
    width: 100%;
    @media only screen and (max-width: 769px) {
      padding: 15px;
    }
    .p-carousel-indicator {
      .p-link {
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
    }
    .p-carousel-indicator.p-highlight {
      .p-link {
        background: $primary-color !important;
      }
    }
    .p-carousel-container {
      .p-carousel-prev.p-link {
        @media only screen and (max-width: 769px) {
          display: none;
        }
      }
      .p-carousel-next.p-link {
        @media only screen and (max-width: 769px) {
          display: none;
        }
      }
    }
    .p-items-hidden .p-carousel-item {
      width: 0%;
    }
  }

  .see-future {
    width: 100%;
    height: auto;
    max-width: 1440px;
    margin: auto;
    padding: 5%;
    @include flex(row, center, center);
    @media only screen and (max-width: 769px) {
      height: auto;
      flex-direction: column;
      text-align: center;
      padding: 0 5%;
    }
    .left {
      width: 55%;
      height: 100%;
      @include flex(column, space-between, space-between);
      @media only screen and (max-width: 769px) {
        width: 100%;
        padding: 35px 0;
      }
      .header {
        font-weight: lighter;
        font-size: 4.5rem;
        line-height: 4rem;
        margin-bottom: 35px;
        font-family: Akkurat-Light;
        @media only screen and (max-width: 769px) {
          font-size: 39px;
          line-height: 40px;
        }
      }
      .description {
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: lighter;
        font-family: Akkurat-Light;
        width: 75%;
        @media only screen and (max-width: 769px) {
          font-size: 15px;
          line-height: 20px;
          width: 80%;
          margin: auto;
          text-align: center;
          margin-bottom: 25px;
        }
      }
      .see-future-img-container {
        width: 50%;
        height: 80%;
        @media only screen and(max-width: 769px) {
          width: 100%;
        }
        .see-future-img {
          height: 100%;
          width: 100%;
          object-fit: contain;
          margin: 10% 0;
          @media only screen and (max-width: 769px) {
            margin: 0 auto 25px;
            width: 80%;
          }
        }
      }
      .get-the-app-btn-container {
        height: 15%;
        @include flex(row, flex-start, center);
        .get-the-app-btn {
          border: none;
          background-color: $primary-color;
          color: white;
          width: 189px;
          text-transform: uppercase;
          height: 48px;
          font-size: 17px;
          font-weight: bold;
          border-radius: 35px;
          @media only screen and (max-width: 769px) {
            margin: auto;
          }
        }
      }
    }
    .right {
      width: 35%;
      @include flex(column, space-between, center);
      @media only screen and (max-width: 769px) {
        width: 100%;
      }
      .header {
        font-size: 24px;
        font-family: Akkurat-Bold;
        @media only screen and (max-width: 769px) {
          font-size: 20px;
          line-height: 30px;
        }
      }
      .pie-chart-img-container {
        width: 70%;
        .pie-chart-img {
          width: 100%;
          object-fit: contain;
        }
      }
      .ticker-card-container {
        width: 100%;
        padding: 15px;
        .ticker-card {
          box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
            3px 1px 5px rgba(86, 86, 86, 0.2),
            inset 0px 0px 2px rgba(255, 255, 255, 0.21);
          height: 100%;
          border-radius: 25px;
          @include flex(column, center, center);
          .header {
            @include flex(column, space-between, center);
            height: 20%;
            text-align: center;
            width: 80%;
            margin: auto;
            border-bottom: 0.25px solid rgba(0, 0, 0, 0.15);
            padding-top: 15px;
            .ticker {
              font-family: Akkurat-Bold;
              font-size: 30px;
            }
            .price {
              font-family: Akkurat-Light;
              font-size: 37px;
              color: $primary-color;
            }
          }
          .ticker-content {
            height: 70%;
            width: 80%;
            padding: 25px 0;
            margin: auto;
            text-align: center;
            :first-child {
              align-items: flex-start !important;
            }
            :last-child {
              align-items: flex-end !important;
            }
            @include flex(row, space-between, center);
            .ticker-col {
              @include flex(column, center, center);
              flex: 1;
              .heading {
                font-family: Akkurat-Bold;
                color: $primary-color;
                font-size: 15px;
              }
              p {
                font-size: 15px;
              }
            }
          }
        }
      }
      .get-the-app-btn-container {
        height: 15%;
        @include flex(row, flex-start, center);
        .get-the-app-btn {
          border: none;
          background-color: $primary-color;
          color: white;
          width: 189px;
          text-transform: uppercase;
          height: 48px;
          font-size: 17px;
          font-weight: bold;
          border-radius: 35px;
          @media only screen and (max-width: 769px) {
            margin: 30px auto;
            margin-bottom: 50px;
          }
        }
      }
    }
  }

  .zero-emotions {
    width: 100%;
    height: auto;
    color: white;
    background-color: #3728e4;
    padding: 5% 0;
    @media only screen and (max-width: 769px) {
      height: auto;
    }
    .zero-emotions-content {
      max-width: 1440px;
      padding: 0 5%;
      margin: auto;
      @include flex(column, center, flex-start);
      @media only screen and (max-width: 769px) {
        height: auto;
        padding: 35px;
      }
      .zero-header {
        padding-bottom: 25px;
        font-weight: lighter;
        font-size: 3.5rem;
        line-height: 4rem;
        margin: 0;
        font-family: Akkurat-Light;
        @media only screen and (max-width: 769px) {
          font-size: 51px;
          line-height: 55px;
          text-align: center;
        }
      }
      .zero-content {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 769px) {
          flex-direction: column;
        }
        .zero-left {
          // width: 50%;
          // @include flex(column, space-between, flex-start);
          // height: 100%;
          width: 50%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          @media only screen and (max-width: 769px) {
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
          }
          .zero-top {
            width: 100%;
            flex: 1;
            .left-sub-header {
              font-size: 1.3rem;
              line-height: 2rem;
              font-family: Akkurat-Bold;
              width: 70%;
              flex: 1;
              @media only screen and (max-width: 769px) {
                text-align: center;
                font-size: 15px;
                line-height: 20px;
                width: 100%;
                font-family: Akkurat;
              }
            }
          }
          .zero-bottom {
            width: 100%;
            .zero-btn {
              width: 189px;
              height: 48px;
              font-size: 17px;
              font-weight: bold;
              border: none;
              background-color: #3728e4;
              color: white;
              border: 1px solid #ffffff;
              border-radius: 35px;
              text-transform: uppercase;
              margin: 25px 0;
            }
          }
        }
        .zero-right {
          width: 50%;
          height: 100%;
          @include flex(column, space-between, center);
          @media only screen and (max-width: 769px) {
            align-items: center;
            width: 100%;
          }
          .right-title {
            font-size: 1.3rem;
            line-height: 2rem;
            font-family: Akkurat-Bold;
            @media only screen and (max-width: 769px) {
              text-align: center;
              font-size: 15px;
              line-height: 20px;
            }
          }
          .zero-bar-chart {
            max-width: 100%;
            max-height: 80%;
          }
          .right-footer {
            font-size: 1rem;
            line-height: 1.7rem;
            font-weight: lighter;
            font-family: Akkurat-Light;
            cursor: pointer;
            @media only screen and (max-width: 769px) {
              text-align: center;
              margin-top: 25px;
              font-size: 15px;
              line-height: 15px;
            }
          }

          .zero-btn {
            width: 189px;
            height: 48px;
            font-size: 17px;
            font-weight: bold;
            border: none;
            background-color: #3728e4;
            color: white;
            border: 1px solid #ffffff;
            border-radius: 35px;
            text-transform: uppercase;
            margin: 25px 0;
          }
        }
      }
    }
  }
  .automate-crypto {
    height: auto;
    width: 100%;
    @media only screen and (max-width: 769px) {
      height: auto;
      padding: 15px;
    }
    .header {
      font-size: 4rem;
      font-weight: lighter;
      width: 60%;
      margin: 0 auto;
      font-family: Akkurat-Light;
      text-align: center;
      line-height: 81px;
      height: 30%;
      @include flex(row, center, center);
      @media only screen and (max-width: 769px) {
        font-size: 40px;
        line-height: 40px;
        width: 100%;
      }
    }

    .automate-crypto-content {
      height: 55%;
      @include flex(row, space-evenly, center);
      @media only screen and (max-width: 769px) {
        flex-direction: column;
      }
      .automate-crypto-card {
        width: 30%;
        height: 100%;
        box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
          3px 1px 5px rgba(86, 86, 86, 0.2),
          inset 0px 0px 2px rgba(255, 255, 255, 0.21);
        border-radius: 10px;
        background: #f6f6f6;
        @media only screen and (max-width: 769px) {
          width: 100%;
          margin: 15px 0;
        }
        .card-img {
          height: 60%;
        }

        .card-img-description-container {
          height: 30%;
          padding: 25px;
          .card-img-header {
            font-weight: bold;
            font-size: 21px;
            line-height: 22px;
          }
          .card-img-description {
            font-weight: lighter;
            font-family: Akkurat-Light;
            font-size: 17px;
            line-height: 22px;
          }
        }

        .learn-more-container {
          height: 10%;
          border-top: 0.25px solid rgba(0, 0, 0, 0.1);
          @include flex(row, center, center);
          @media only screen and (max-width: 769px) {
            height: 45px;
          }
          .learn-more-text {
            text-align: center;
            color: $primary-color;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 15px;
            margin: 0;
            cursor: pointer;
          }
        }
      }
    }

    .get-the-app-btn-container {
      height: 15%;
      @include flex(row, center, center);
      .get-the-app-btn {
        border: none;
        background-color: $primary-color;
        color: white;
        width: 189px;
        text-transform: uppercase;
        height: 48px;
        font-size: 17px;
        font-weight: bold;
        border-radius: 35px;

        @media only screen and (max-width: 769px) {
          border: none;
          background-color: $primary-color;
          color: white;
          width: 189px;
          height: 48px;
          font-size: 17px;
          font-weight: bold;
          border-radius: 35px;
        }
      }
    }
  }
}

.images-dialog {
  .images {
    width: 100%;
    height: 100%;
    display: flex;
    @media only screen and (max-width: 769px) {
      flex-direction: column;
    }
    .image-container {
      flex: 1;
      margin: 15px;
      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .custom-legend {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 15px;
    .legend {
      margin: 0;
    }
    .ar {
      color: rgba(241, 214, 147, 1);
    }
    .sp {
      color: rgba(3, 201, 169, 1);
    }
  }
}
