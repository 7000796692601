@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.forgot-password-container {
  min-height: 100vh;
  min-width: 100vw;
  background-color: $primary-color;
  @include flex(row, center, center);
  .forgot-password-form-container {
    @media only screen and (max-width: 769px) {
      width: 100%;
    }
    .forgot-password-header {
      line-height: 43px;
      font-size: 53px;
      color: white;
      text-align: center;
      margin-bottom: 35px;
      @media only screen and (max-width: 769px) {
        line-height: 23px;
        font-size: 32px;
        color: white;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 15%;
      }
    }
    .forgot-password-form {
      padding: 35px;
      background-color: white;
      border-radius: 5px;
      width: 500px;
      @media only screen and (max-width: 769px) {
        min-width: auto;
        width: 90%;
        margin: auto;
      }

      .forgot-password-input {
        font-size: 17px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(128, 128, 128, 0.5);
        width: 100%;
        height: 64px;
      }
      .forgot-password-btn-container {
        @include flex(row, flex-end, center);
        @media only screen and (max-width: 769px) {
          justify-content: center;
        }
        .forgot-password-button {
          border: none;
          width: 163px;
          height: 64px;
          background-color: $primary-color;
          color: white;
          font-weight: bold;
          font-size: 22px;
          border-radius: 5px;
          text-transform: uppercase;
          margin-top: 25px;
        }
      }
    }
    .error {
      margin: 0;
      margin-top: 30px;
    }
    .success {
      margin: 0;
      margin-top: 30px;
    }
    .signin-btn {
      text-align: center;
      margin: 0;
    }
  }
  .space-top {
    margin-top: 15px;
  }

  .space-bottom {
    margin-bottom: 15px;
  }
}
