.change-password-input {
  font-size: 17px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  width: 100%;
  height: 64px;
}

.space-bottom {
  margin-bottom: 15px;
}
