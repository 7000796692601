@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.alerts-form-container {
  .heading {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  .input-container {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 769px) {
      flex-direction: column;
    }

    .p-inputtext {
      border-radius: 25px;
    }

    .set-button {
      color: white;
      background: $primary-color;
      border-radius: 25px;
      min-width: 100px;
      margin-left: 20px;

      @media screen and (max-width: 769px) {
        width: 50px;
        margin: auto;
        margin-top: 15px;
      }
    }
  }

  .indicators-container {
    .sub-header {
      color: grey;
      margin-top: 15px;
      text-align: center;
    }
    .indicators {
      .indicators-row {
        justify-content: center;
        display: flex;
        margin: 15px 0;
        @media screen and (max-width: 769px) {
          flex-direction: column;
        }
        .button {
          min-width: 180px;
          min-height: 50px;
          color: grey;
          margin: 0 10px;
          border-radius: 25px;
          background: rgba(128, 128, 128, 0.3);
          @media screen and (max-width: 769px) {
            margin-top: 10px;
          }
        }
        .button-active {
          min-width: 180px;
          min-height: 50px;
          margin: 0 10px;
          color: white;
          background: $primary-color;
          border-radius: 25px;
          @media screen and (max-width: 769px) {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .crossovers-container {
    .sub-header {
      text-align: center;
      color: grey;
      margin-top: 15px;
    }

    .crossovers {
      display: flex;
      justify-content: center;
      @media screen and (max-width: 769px) {
        flex-direction: column;
      }
      .button {
        min-width: 180px;
        min-height: 50px;
        color: grey;
        background: rgba(128, 128, 128, 0.3);
        border-radius: 25px;
        margin: 0 10px;
        @media screen and (max-width: 769px) {
          margin-top: 10px;
        }
      }
    }
  }

  .alerts-container {
    padding: 5px;
    box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
      3px 1px 5px rgba(86, 86, 86, 0.2),
      inset 0px 0px 2px rgba(255, 255, 255, 0.21);
    border-radius: 25px;
    margin-top: 50px;
    background: rgba(128, 128, 128, 0.3);
    .heading {
      text-align: left;
      font-weight: normal;
      padding: 20px;
    }

    .alerts-row {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      .alerts-price {
        margin-top: 1rem;
        font-weight: bold;
        font-size: 15px;
      }
      .cancel-button {
        margin-top: 1rem;
        font-size: 15px;
        color: $negative-color;
        cursor: pointer;
      }

      .cancel-button:hover {
        color: red;
      }
    }
  }
}
