.provide-list-title{
    font-size: 3rem;
    color: $theme-green;
    margin-bottom: auto;
}

.icons-row{
    padding: 0rem 1rem 1rem 1rem;
}

.icons-col{
    margin-top: 2rem;;
}

.icons{
    border: 1px solid $font-text-grey;
    border-radius: 25px;
    width: 85%;
}

@media only screen and (max-width: 600px){
    .provide-list-title{
        font-size: 2rem;
        color: $theme-green;
        padding: 0rem 0rem 0rem 0.4rem;
    }
    
    .icons-row{
        padding: 0rem 1.3rem 1rem 0rem;
    }
    
    .icons-col{
        margin-top: 1rem;;
    }
    
    .icons{
        border: 1px solid $font-text-grey;
        border-radius: 15px;
        width: 115%;
    }
} 